import { createWebHistory, createRouter } from "vue-router";
import Index from "@/pages/PageIndex.vue";
import Dashboard from "@/pages/PageDashboard.vue";
import ServerGeneral from "@/pages/server/[guildId]/PageGeneral.vue";
import ServerDictionary from "@/pages/server/[guildId]/PageDictionary.vue";
import Commands from "@/pages/PageCommands.vue";
import ServerMember from "@/pages/server/[guildId]/PageMember.vue";
import ServerChannel from "@/pages/server/[guildId]/PageChannel.vue";
import ServerModerator from "@/pages/server/[guildId]/PageModerator.vue";
import PrivacyPolicy from "@/pages/PagePrivacyPolicy.vue";
import TermsOfService from "@/pages/PageTermsOfService.vue";
import Error404 from "@/pages/PageError404.vue";
import FrequentlyAskedQuestions from "@/pages/PageFrequentlyAskedQuestions.vue";
import PlanList from "@/pages/PagePlanList.vue";
import Tokusho from "@/pages/PageTokusho.vue";

const Router = createRouter({
  history: createWebHistory("/"),
  routes: [
    { path: "/404", component: Error404, name: "404", meta: { layout: "NoneLayout" } },
    { path: "/", component: Index, name: "index", meta: { layout: "LpLayout" } },
    { path: "/plan", component: PlanList, name: "plan", meta: { layout: "LpLayout" } },
    { path: "/commands", component: Commands, name: "commands", meta: { layout: "LpLayout" } },
    { path: "/privacy", component: PrivacyPolicy, name: "privacy", meta: { layout: "LpLayout" } },
    { path: "/tos", component: TermsOfService, name: "tos", meta: { layout: "LpLayout" } },
    { path: "/tokusho", component: Tokusho, name: "tokusho", meta: { layout: "LpLayout" } },
    { path: "/questions", component: FrequentlyAskedQuestions, name: "questions", meta: { layout: "LpLayout" } },
    { path: "/dashboard", component: Dashboard, name: "dashboard" },
    { path: "/server/:guildId/general", component: ServerGeneral, name: "server/:guildId/general" },
    { path: "/server/:guildId/dictionary", component: ServerDictionary, name: "server/:guildId/dictionary" },
    { path: "/server/:guildId/member", component: ServerMember, name: "server/:guildId/member" },
    { path: "/server/:guildId/channel", component: ServerChannel, name: "server/:guildId/channel" },
    { path: "/server/:guildId/moderator", component: ServerModerator, name: "server/:guildId/moderator" },

    { path: "/:catchAll(.*)", redirect: "/404" },
  ],
});

export default Router;
