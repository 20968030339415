<template>
  <div v-if="!isSubscriptionDebugUser" class="w-100 d-flex justify-center align-center text-h2" :style="{ height: 'calc(100dvh - 64px)' }">
    Coming soon
  </div>
  <div v-else class="p-plan mx-auto">
    <v-table class="p-plan__table bg-transparent">
      <thead>
        <tr class="text-h5">
          <th class="text-white font-weight-bold text-center p-plan__col-label"></th>
          <th class="text-white font-weight-bold text-center">くらすめーと</th>
          <th class="text-white font-weight-bold text-center">ともだち</th>
          <th class="p-plan__osusume p-plan__osusume--top text-white font-weight-bold text-center">
            <v-chip class="mb-3" color="yellow">おすすめ！</v-chip><br>
            しんゆう
          </th>
          <th class="text-white font-weight-bold text-center">かぞく</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="label in labels">
          <td>
            {{ label.name }}
            <sup v-if="label.key === 'amount'">※1</sup>
          </td>
          <td v-for="row in data" class="text-center" :class="{ 'p-plan__osusume': row.plan === 'advance', 'p-plan__osusume--bottom': row.plan === 'advance' && label.key === 'botCount' }">
            <template v-if="label.key === 'amount'">
              <span class="text-h5" :class="{ 'text': row.plan !== 'basic' }">{{ row[label.key].text }}</span>
            </template>
            <template v-else-if="label.key === 'correctSpeechTextCounts'">
              <span class="text-h5" :class="{ 'text': row.plan !== 'basic' }">{{ row[label.key].text }} 万</span>
            </template>
            <template v-else-if="row[label.key].text">
              <template v-for="(line, index) in row[label.key].text.split(`\n`)">
                <span :class="{ 'text': row.plan !== 'basic' && !(row.plan === 'standard' && label.key === 'botCount') }">{{ line }}</span>
                <br v-if="index !== row[label.key].text.split(`\n`).length - 1">
              </template>
            </template>
            <v-icon v-else :color="row[label.key].color">{{ row[label.key].icon }}</v-icon>
            <sup v-if="row[label.key].annotation" class="d-inline">
              ※{{ row[label.key].annotation }}
            </sup>
          </td>
        </tr>
      </tbody>
    </v-table>

    <div class="my-2">
      <div>※1 サーバー単位での契約となります。</div>
      <div>※2 話者は今後追加予定です。</div>
      <div>※3 BOT台数は今後追加予定です。</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/useUserStore"
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const { debugDiscordUserIds } = useRuntimeConfig();

const isSubscriptionDebugUser = computed(() => {
  return debugDiscordUserIds.includes(user.value?.id);
})

const labels = [
  { name: "月額料金", key: "amount" },
  { name: "月の読み上げ枠", key: "correctSpeechTextCounts" },
  { name: "話者", key: "speaker" },
  { name: "課金者専用問い合わせ", key: "dedicatedSupport" },
  { name: "スピード変更", key: "speed" },
  { name: "ピッチ変更", key: "pitch" },
  { name: "BOTの読み上げ", key: "readBotMessage" },
  { name: "最大メッセージ長", key: "maxMessageLen" },
  { name: "辞書登録数", key: "maxDictionaryCount" },
  { name: "BOT台数", key: "botCount" },
]
const data = [
  {
    plan: "basic",
    amount: { text: "無料" },
    correctSpeechTextCounts: { text: "5" },
    speaker: { text: "結月ゆかり\n紲星あかり\n琴葉 葵" },
    maxMessageLen: { text: "60 文字" },
    maxDictionaryCount: { text: "50 単語" },
    speed: { icon: "mdi-minus", color: "inactive" },
    pitch: { icon: "mdi-minus", color: "inactive" },
    readBotMessage: { icon: "mdi-minus", color: "inactive" },
    dedicatedSupport: { icon: "mdi-minus", color: "inactive" },
    botCount: { text: "1 台" },
  },
  {
    plan: "standard",
    amount: { text: "¥200" },
    correctSpeechTextCounts: { text: "30" },
    maxMessageLen: { text: "140 文字" },
    maxDictionaryCount: { text: "300 単語" },
    speaker: { text: "全員", annotation: 2 },
    speed: { icon: "mdi-minus", color: "inactive" },
    pitch: { icon: "mdi-minus", color: "inactive" },
    readBotMessage: { icon: "mdi-minus", color: "inactive" },
    dedicatedSupport: { icon: "mdi-check" },
    botCount: { text: "1 台" },
  },
  {
    plan: "advance",
    amount: { text: "¥500" },
    correctSpeechTextCounts: { text: "100" },
    maxMessageLen: { text: "140 文字" },
    maxDictionaryCount: { text: "300 単語" },
    speaker: { text: "全員", annotation: 2 },
    speed: { icon: "mdi-check" },
    pitch: { icon: "mdi-check" },
    readBotMessage: { icon: "mdi-minus", color: "inactive" },
    dedicatedSupport: { icon: "mdi-check" },
    botCount: { text: "1 台以上", annotation: 3 },
  },
  {
    plan: "pro",
    amount: { text: "¥1,000" },
    correctSpeechTextCounts: { text: "300" },
    maxMessageLen: { text: "140 文字" },
    maxDictionaryCount: { text: "300 単語" },
    speaker: { text: "全員", annotation: 2 },
    speed: { icon: "mdi-check" },
    pitch: { icon: "mdi-check" },
    readBotMessage: { icon: "mdi-check" },
    dedicatedSupport: { icon: "mdi-check" },
    botCount: { text: "1 台以上", annotation: 3 },
  },
]
</script>

<style lang="scss">
.p-plan__table-top th {
  border: none;
  background-color: transparent;
}

.p-plan {
  margin: 16px 0 100px;
  max-width: 1000px;
}

.p-plan__title {
  margin-bottom: 60px;
}

.p-plan__table {
  th {
    height: 100px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    vertical-align: bottom;
  }

  td, th {
    border-top-color: #4c4c4c !important;
    border-bottom-color: #4c4c4c !important;
  }

  .p-plan__osusume {
    border-left: 2px solid #FFF176 !important;
    border-right: 2px solid #FFF176 !important;
    background-color: rgba(#FFF176, 0.075) !important;
  }
  
  .p-plan__osusume--top {
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 2px solid #FFF176 !important;
  }
  
  .p-plan__osusume--bottom {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 2px solid #FFF176 !important;
  }
}


.p-plan__col-label {
  width: 180px;
}
</style>
