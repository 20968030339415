<template>
  <v-card>
    <v-toolbar theme="dark">
      <v-btn icon @click="emit('update:modelValue', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>使い方</v-toolbar-title>
      <v-spacer />
      <v-btn variant="tonal" size="large" :href="howToUrl" target="_blank" rel="noopener noreferrer">
        PDFを表示 <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': mobile }">
      <div class="d-flex">
        <div class="vcHowToPdfContainer_navigation">
          <v-list>
            <v-list-item
              v-for="navigation in navigations"
              :key="navigation.name"
              :title="navigation.name"
              :active="navigation.active"
              @click="navigateTo(navigation.page)"
            />
          </v-list>
        </div>
        <div class="vcHowToPdfContainer_main js-pdf-container" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useDisplay } from "vuetify";
import * as PdfJsLib from "pdfjs-dist";
import PdfJsWorker from "pdfjs-dist/build/pdf.worker.js?worker";
import VueScrollTo from "vue-scrollto";
import howToUrl from "@/assets/how_to_use.pdf";
import { useDebounce } from "@/composable/useLodash";
import { CMAP_URL } from "@/const";

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: boolean): void;
}>();

PdfJsLib.GlobalWorkerOptions.workerPort = new PdfJsWorker();

const initialized = ref(false);
const imageHeight = ref(0);
const canvasHeight = ref(0);
const canvasWidth = ref(0);
const containerScrollTop = ref(0);
const containerClientWidth = ref(0);
let onResizeWindow: () => void;

const navigations = ref([
  { name: "ログイン画面", page: 3, active: false },
  { name: "サーバー選択画面", page: 6, active: false },
  { name: "一般設定", page: 14, active: false },
  { name: "メンバー設定", page: 31, active: false },
  { name: "チャンネル設定", page: 38, active: false },
  { name: "辞書設定", page: 48, active: false },
  { name: "モデレーター設定", page: 53, active: false },
]);

const navigateTo = (page: number) => {
  const duration = 500;
  const options = {
    container: ".js-pdf-container",
    easing: "ease-in",
    lazy: false,
    offset: 240,
    force: true,
    cancelable: false,
  };
  VueScrollTo.scrollTo(`#howto-pdf-${page}`, duration, options);
};

const setup = async () => {
  const container = document.querySelector(".js-pdf-container");
  if (!container) {
    return;
  }

  container?.replaceChildren();
  const task = PdfJsLib.getDocument({
    url: howToUrl,
    cMapUrl: CMAP_URL,
    cMapPacked: true,
    useWorkerFetch: false,
  });
  const pdf = await task.promise;

  const setCanvasWrapperHeight = (canvasWrapper: HTMLDivElement) => {
    containerClientWidth.value = container.clientWidth;
    imageHeight.value = Math.ceil((canvasHeight.value * containerClientWidth.value) / canvasWidth.value + 10);
    canvasWrapper.style.height = imageHeight.value + "px";
  };

  const onChangeContainerScrollTop = useDebounce(() => {
    containerScrollTop.value = container.scrollTop;
    navigations.value.forEach((navigation) => {
      navigation.active = false;
    });

    const currentNavigation = [...navigations.value].reverse().find((navigation) => {
      const currentPage = Math.floor(containerScrollTop.value / imageHeight.value) + 1;
      return currentPage >= navigation.page;
    });
    if (!currentNavigation) return;

    currentNavigation.active = true;
  }, 20);

  container.addEventListener("scroll", onChangeContainerScrollTop);

  onResizeWindow = () => {
    containerClientWidth.value = container.clientWidth;
    [...container.children].forEach((canvasWrapper) => {
      setCanvasWrapperHeight(canvasWrapper as HTMLDivElement);
    });
    onChangeContainerScrollTop();
  };

  window.addEventListener("resize", onResizeWindow);

  [...Array(pdf.numPages)].forEach(async (_, i) => {
    const page = await pdf.getPage(i + 1);
    const scale = 2;
    const viewport = page.getViewport({ scale });
    const outputScale = window.devicePixelRatio || 1;
    const canvasWrapper = document.createElement("div");
    const canvas = document.createElement("canvas");
    canvasWrapper.appendChild(canvas);
    const context = canvas.getContext("2d");
    if (context === null) {
      throw "Fail to get canvas context.";
    }

    canvasWrapper.id = `howto-pdf-${i + 1}`;
    canvas.width = Math.floor(viewport.width * outputScale);
    canvas.height = Math.floor(viewport.height * outputScale);
    canvas.style.width = "100%";
    canvas.style.marginBottom = "8px";
    canvas.style.border = "1px solid white";
    container.appendChild(canvasWrapper);
    const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined;

    canvasHeight.value = canvas.height;
    canvasWidth.value = canvas.width;
    setCanvasWrapperHeight(canvasWrapper);

    page.render({
      canvasContext: context,
      transform,
      viewport,
    });
  });
};

const { mobile } = useDisplay();

const onContainerLoaded = async (f: () => void) => {
  const intervalID = setInterval(() => {
    const container = document.querySelector(".js-pdf-container");
    if (container?.clientWidth && container.clientWidth > 100) {
      f();
      clearInterval(intervalID);
    }
  }, 50);
};

onMounted(() => {
  if (!mobile.value && !initialized.value) {
    onContainerLoaded(() => {
      setup();
      initialized.value = true;
    });
  }
});

watch(props, () => {
  if (props.modelValue && initialized.value) {
    onContainerLoaded(onResizeWindow);
  }
});
</script>

<style lang="scss" setup>
.vcHowToPdfContainer_navigation {
  width: 200px;
}

.vcHowToPdfContainer_main {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 128px);
  padding-right: 24px;
}
</style>
