import type { Ref } from "vue";
import { defineStore } from "pinia";
import { computed } from "vue";
import { useDebounce } from "@/composable/useLodash";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const onAfterUpdate = (f: () => void) => useDebounce(f, 500);

export const useAsyncUpdaterStore = defineStore("async_updater", {
  state: () => {
    return {
      queue: [] as (() => Promise<void>)[],
    };
  },

  actions: {
    createPropertyUpdater<T extends NonNullable<unknown>>(
      obj: Ref<T | undefined> | (() => Ref<T | undefined>),
      f: (params: { [key: string]: T[keyof T] | null }, g: typeof onAfterUpdate) => () => Promise<void>,
    ) {
      return (property: keyof T) => {
        const debouncedRegister = useDebounce(
          (newValue: T[keyof T] | null) => this.register(f({ [property]: newValue }, onAfterUpdate)),
          500,
        );
        return computed({
          get() {
            const ref = obj instanceof Function ? obj() : obj;
            const value = ref.value && ref.value[property];
            return value === undefined ? null : value;
          },
          set(newValue) {
            const ref = obj instanceof Function ? obj() : obj;
            if (!ref.value) return;
            if (ref.value[property] !== newValue) {
              ref.value[property] = newValue as T[keyof T];
              debouncedRegister(newValue === undefined ? null : newValue);
            }
          },
        });
      };
    },
    register(f: () => Promise<void>) {
      this.queue.length ? this.queue.push(f) : this.execute(f);
    },
    execute(f: () => Promise<void>) {
      f()
        .catch(() => useSnackbarStore().addDefaultUpdateError())
        .then(() => {
          const f = this.queue.shift();
          if (f) {
            this.execute(f);
          }
        });
    },
  },
});
