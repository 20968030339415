import type { AxiosError } from "axios";
import type { Channel } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useVGuildStore } from "./useVGuildStore";

const handleError = (_axiosError: AxiosError) => {
  useSnackbarStore().addDefaultGetError();
};

const associateParent = (channel: Channel) => {
  channel.children.forEach((child) => {
    child.parent = channel;
    associateParent(child);
  });
};

export const useChannelStore = defineStore("channels", {
  state: () => {
    return {
      channels: [] as Channel[],
      currentChannel: undefined as Channel | undefined,
    };
  },

  actions: {
    async fetchChannels() {
      const guildId = useVGuildStore().vGuild.guild_id;
      if (guildId === "0") return;

      const response = await useAPI().get(`/api/v_guilds/${guildId}/channels`).catch(handleError);
      if (!response) return;

      this.channels = response.data;
      this.channels.forEach((channel) => associateParent(channel));
    },
    updateCurrentChannel(channel?: Channel) {
      this.currentChannel = channel;
    },
  },
});
