import type { AxiosError } from "axios";
import type { Guild } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

export type GuildsByStatus = {
  managed_joined: Guild[];
  not_managed_joined: Guild[];
  managed_not_joined: Guild[];
};

const handleError = (_axiosError: AxiosError) => {
  useSnackbarStore().addDefaultGetError();
};

export const useGuildStore = defineStore("guilds", {
  state: () => {
    return {
      guilds: [] as Guild[],
      guild: undefined as Guild | undefined,
    };
  },

  actions: {
    async setGuilds() {
      const api = useAPI({ timeout: 15000 });
      const response = await api.get("/api/guilds").catch(handleError);
      if (!response) return;

      this.guilds = response.data;
    },
    setGuild(guild?: Guild) {
      this.guild = guild;
    },
  },

  getters: {
    isGuildNotManaged({ guild }) {
      return guild?.status === "not_managed_joined";
    },
    managedJoinedGuilds({ guilds }): Guild[] {
      return guilds.filter((guild) => guild.status === "managed_joined");
    },
    notManagedJoinedGuilds({ guilds }): Guild[] {
      return guilds.filter((guild) => guild.status === "not_managed_joined");
    },
    managedNotJoinedGuilds({ guilds }): Guild[] {
      return guilds.filter((guild) => guild.status === "managed_not_joined");
    },
  },
});
