import type { AxiosError } from "axios";
import type { User } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const getGuildId = () => useVGuildStore().vGuild.guild_id;

const handleError = (_axiosError: AxiosError) => {
  useSnackbarStore().addDefaultGetError();
};

interface SearchParams {
  query: string;
  search_user_id: boolean;
  search_name: boolean;
}

export const useMemberStore = defineStore("members", {
  state: () => {
    return {
      members: [] as User[],
    };
  },

  actions: {
    async fetchFromVGuild() {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI({ timeout: 20000 }).get(`/api/v_guilds/${guildId}/members`).catch(handleError);
      if (!response) return;

      this.members = response.data;
    },
    async searchFromVGuild(params: SearchParams) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI().get(`/api/v_guilds/${guildId}/members/search`, { params }).catch(handleError);
      if (!response) return [];

      return response.data;
    },
  },
});
