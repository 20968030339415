<template>
  <VcGuildInvite />
  <div
    v-if="vGuild && vGuild.id !== '0'"
    class="h-100 w-100 d-flex flex-column p-dictionary"
    :class="{ 'is-mobile': mobile }"
  >
    <div class="p-dictionary__header v-container pa-0">
      <div v-if="!mobile" class="p-dictionary__pc-header bg-background">
        <v-text-field
          v-model="searchText"
          class="p-dictionary__search"
          prepend-inner-icon="mdi-magnify"
          placeholder="辞書を検索"
          variant="solo"
          hide-details
        />
        <div>
          <v-checkbox
            v-model="searchBefore"
            class="p-dictionary__search-target"
            label="変換前"
            density="compact"
            color="blue-accent-2"
            hide-details
          />
          <v-checkbox
            v-model="searchAfter"
            class="p-dictionary__search-target"
            label="変換後"
            density="compact"
            color="blue-accent-2"
            hide-details
          />
        </div>
        <v-spacer />
        <template v-if="onDelete">
          <v-btn
            class="mr-2"
            color="danger"
            prepend-icon="mdi-trash-can"
            size="large"
            @click="confirmDeleteRef?.open()"
          >
            削除({{ selectedIds.length }})
          </v-btn>
          <v-btn color="grey" size="large" @click="selectedIds = []"> キャンセル </v-btn>
        </template>
        <v-btn v-else color="success" prepend-icon="mdi-plus" size="large" @click="openDialog()"> 追加 </v-btn>
      </div>
      <div class="d-flex bg-surface-lighten-1">
        <div class="p-dictionary__checkcell">
          <v-checkbox v-model="selecteState" density="compact" color="danger" hide-details />
        </div>
        <div class="p-dictionary__cell is-word">変換前</div>
        <div class="p-dictionary__cell is-word">変換後</div>
        <div v-if="showMember" class="p-dictionary__cell is-user">登録者</div>
      </div>
    </div>

    <div class="p-dictionary__body">
      <div
        v-for="dictionary in displayDictionaries"
        :key="dictionary.id.toString()"
        v-ripple
        class="d-flex p-dictionary__row"
        @click="onClickItem(dictionary)"
      >
        <div class="p-dictionary__checkcell">
          <v-checkbox
            :model-value="selectedIds.includes(dictionary.id)"
            hide-details
            density="compact"
            color="danger"
            @update:model-value="toggleSelect(dictionary)"
            @click.stop
          />
        </div>
        <div class="p-dictionary__cell is-word" :class="{ 'text-caption': mobile }">
          {{ dictionary.before }}
        </div>
        <div class="p-dictionary__cell is-word" :class="{ 'text-caption': mobile }">
          {{ dictionary.after }}
        </div>
        <div
          v-if="showMember"
          class="p-dictionary__cell p-dictionary__cell-user d-flex align-center"
          :class="{ 'justify-center': mobile }"
        >
          <template v-if="dictionary.user">
            <v-avatar :color="dictionary.user.accent_color || 'primary'" size="32">
              <v-img :src="dictionary.user.image" width="32" />
            </v-avatar>
            <div v-if="!mobile" class="p-dictionary__cell-user_name ml-1">
              <div class="text-subtitle-2">
                {{ dictionary.user.nick || dictionary.user.name }}
              </div>
              <div class="text-caption text-grey">{{ dictionary.user.username }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="mobile" class="p-dictionary__footer">
      <div v-if="onDelete" class="d-flex">
        <div class="w-50 pa-0 bg-danger">
          <v-btn
            class="h-100 w-100 p-dictionary__cell is-word"
            prepend-icon="mdi-trash-can"
            variant="text"
            size="x-large"
            @click="confirmDeleteRef?.open()"
          >
            削除({{ selectedIds.length }})
          </v-btn>
        </div>
        <div class="w-50 pa-0 bg-grey">
          <v-btn class="h-100 w-100 p-dictionary__cell is-word" variant="text" size="x-large" @click="selectedIds = []">
            キャンセル
          </v-btn>
        </div>
      </div>
      <div v-else class="v-container pa-0 bg-success">
        <v-btn
          class="h-100 w-100 p-dictionary__cell is-word"
          prepend-icon="mdi-plus"
          variant="text"
          size="x-large"
          @click="openDialog()"
        >
          追加
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialog" class="p-dictionary__dialog" :class="{ 'is-mobile': mobile }" :fullscreen="mobile">
      <v-card>
        <v-toolbar theme="dark">
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ currentDictionary ? "辞書更新" : "辞書追加" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn class="mr-1 text-black" color="cancel" tabindex="4" :disabled="!isChanged" @click="reset"
              >戻す</v-btn
            >
            <v-btn color="secondary" tabindex="3" :disabled="!canSave" @click="save">保存</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <div class="mb-5">
            ※変換前に半角文字のみを登録する場合は2文字以上の指定が必要です（日本語や絵文字などは1文字だけで登録可能です）
          </div>

          <v-text-field
            ref="beforeRef"
            v-model="before"
            :rules="[rules.required, rules.uniq, rules.asciiLength]"
            class="mb-2"
            label="変換前"
            autofocus
            autocomplete="new-password"
            aria-autocomplete="none"
            tabindex="1"
            validate-on="blur"
            @blur="validate"
          />
          <v-text-field v-model="after" class="mb-10" label="変換後" autocomplete="new-password" tabindex="2" />
          <v-switch
            v-if="!currentDictionary && false"
            v-model="addGlobal"
            label="グローバル辞書への登録を申請する"
            color="primary"
            hide-details
            inset
          />
          <div v-if="currentDictionary?.user">
            <span class="mr-2">追加したユーザー</span>
            <div class="d-flex align-center mt-2 ml-2">
              <v-avatar :color="currentDictionary.user.accent_color || 'primary'" size="32" class="mr-1">
                <v-img :src="currentDictionary.user.image" width="32" />
              </v-avatar>
              <div class="ml-1 text-left">
                <div class="text-subtitle-2">
                  {{ currentDictionary.user.nick || currentDictionary.user.name }}
                </div>
                <div class="text-caption text-grey">
                  {{ currentDictionary.user.username }}
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <VcConfirm
      ref="confirmDeleteRef"
      title="削除確認"
      message="本当に削除しますか？"
      :actions="[{ name: 'deleteSelected', color: 'danger', text: '削除する' }]"
      @delete-selected="deleteSelected"
    />
  </div>
</template>

<script setup lang="ts">
import type { Dictionary } from "@/types";
import { ref, computed, watch } from "vue";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";
import { VTextField } from "vuetify/components";
import { storeToRefs } from "pinia";
import VcConfirm from "@/components/VcConfirm.vue";
import { useDictionaryStore } from "@/stores/useDictionaryStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const { mobile } = useDisplay();
const route = useRoute();

const beforeRef = ref<InstanceType<typeof VTextField> | null>(null);
const confirmDeleteRef = ref<InstanceType<typeof VcConfirm> | null>(null);

const loadingStore = useLoadingStore();
const dictionaryStore = useDictionaryStore();
const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();

const { dictionaries } = storeToRefs(dictionaryStore);
const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

const showMember = computed(() => {
  return dictionaries.value.some((dictionary) => dictionary.user);
});

const rules = computed(() => ({
  required: (value: string) => !!value || "必須です",
  uniq: (value: string) => {
    return (
      dictionaries.value.every(({ id, before }) => {
        return before !== value.trim() || id === currentDictionary.value?.id;
      }) || "既に登録済みです"
    );
  },
  asciiLength: (value: string) => !value.match(/^[\x20-\x7e]$/) || "半角文字は2文字以上にしてください",
}));

const displayDictionaries = computed(() => {
  const filteredDictionaries = dictionaries.value?.filter(({ before, after }) => {
    return (
      (searchBefore.value && before?.includes(searchText.value)) ||
      (searchAfter.value && after?.includes(searchText.value))
    );
  });
  if (!filteredDictionaries) return [];

  return filteredDictionaries.sort((x: Dictionary, y: Dictionary) => {
    return x.before > y.before ? 1 : x.before < y.before ? -1 : 0;
  });
});

const searchText = ref("");
const searchBefore = ref(true);
const searchAfter = ref(true);
const selectedIds = ref<(string | bigint)[]>([]);
const dialog = ref(false);
const currentDictionary = ref<Dictionary | undefined>(undefined);
const before = ref("");
const after = ref("");
const addGlobal = ref(false);
const isError = ref(false);

const isChanged = computed(() => {
  return before.value !== currentDictionary?.value?.before || after.value !== currentDictionary?.value?.after;
});
const canSave = computed(() => !isError.value && isChanged.value);
const onDelete = computed(() => selectedIds.value.length > 0);
const selecteState = computed({
  get() {
    return onDelete.value;
  },
  set() {
    if (selectedIds.value.length === displayDictionaries.value.length) {
      selectedIds.value = [];
    } else {
      selectedIds.value = displayDictionaries.value.map(({ id }) => id);
    }
  },
});

const toggleSelect = (dictionary: Dictionary) => {
  const index = selectedIds.value.indexOf(dictionary.id);
  if (index >= 0) {
    selectedIds.value.splice(index, 1);
  } else {
    selectedIds.value.push(dictionary.id);
  }
};
const openDialog = (dictionary?: Dictionary) => {
  if (dictionary) {
    currentDictionary.value = dictionary;
    before.value = dictionary.before;
    after.value = dictionary.after;
  } else {
    currentDictionary.value = undefined;
    before.value = "";
    after.value = "";
  }
  addGlobal.value = false;
  dialog.value = true;
};
const onClickItem = (dictionary: Dictionary) => {
  onDelete.value ? toggleSelect(dictionary) : openDialog(dictionary);
};
const closeDialog = () => (dialog.value = false);
const reset = () => {
  before.value = currentDictionary?.value?.before || "";
  after.value = currentDictionary?.value?.after || "";
};
const validate = async () => {
  if (!beforeRef.value) return;

  const errors = await beforeRef.value.validate();
  isError.value = errors.length > 0;
};
const save = async () => {
  await validate();
  if (!canSave.value) return;

  if (currentDictionary.value) {
    await dictionaryStore.update({
      id: currentDictionary.value.id,
      before: before.value,
      after: after.value,
    });
  } else {
    await dictionaryStore.create({ before: before.value, after: after.value });
  }

  dialog.value = false;
};
const deleteSelected = async () => {
  const success = await dictionaryStore.delete(selectedIds.value);

  if (success) {
    selectedIds.value = [];
  }
};

watch(vGuild, async () => {
  if (!route.path.match(/\/server\/\d+\/dictionary/)) return;

  if (vGuild.value.guild_id !== "0") {
    loadingStore.setIsGuildChangeLoading(true);
    selectedIds.value = [];
    await dictionaryStore.fetchFromVGuild();
  }
  loadingStore.setIsGuildChangeLoading(false);
});
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});
</script>

<style lang="scss">
.p-dictionary {
  position: relative;
}

.p-dictionary__pc-header {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.p-dictionary__search {
  width: 50%;
  max-width: 50%;
}

.p-dictionary__search-target {
  width: 80px;
  max-width: 80px;
  margin: -16px 0;
}

.p-dictionary__checkcell {
  height: 48px;
  min-width: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  .v-selection-control {
    justify-content: center;
    flex: 1;
  }
}

.p-dictionary__cell {
  height: 48px;
  line-height: 48px;
  padding: 0 8px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &.is-word {
    flex: 1;
  }

  &.is-user {
    min-width: 180px;
    width: 180px;

    .is-mobile & {
      min-width: 60px;
      width: 60px;
    }
  }
}

.p-dictionary__cell-user {
  min-width: 180px;
  width: 180px;

  .is-mobile & {
    min-width: 60px;
    width: 60px;
  }
}

.p-dictionary__cell-user_name {
  min-width: 130px;
  width: 130px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: right;

  .is-mobile & {
    min-width: 80px;
    width: 80px;
  }
}

.p-dictionary__row {
  border-bottom: 1px solid #555555;
}

.p-dictionary__header {
  top: 64px;
  z-index: 1;
  position: sticky;
  width: 100%;
}

.p-dictionary__body {
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
  padding-bottom: calc(100dvh - 288px);
  scrollbar-width: none;

  .is-mobile & {
    padding-bottom: calc(100dvh - 168px);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.p-dictionary__footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.p-dictionary__dialog {
  width: 720px;

  &.is-mobile {
    width: auto;
  }
}
</style>
