<template>
  <v-tooltip
    location="top"
    content-class="vcGuildTextChannelReadState_tooltip"
    :text="tooltipText"
    :open-on-hover="ignoreByDefault || readByItself"
  >
    <template #activator="{ props: activatorProps }">
      <v-btn-toggle
        v-bind="activatorProps"
        v-model="value"
        class="align-center"
        shaped
        mandatory
        density="compact"
        @click.stop
      >
        <v-btn v-bind="buttonProps" :value="false" color="danger">
          <v-icon size="x-large" :color="value === false ? 'white' : 'danger'"> mdi-close </v-icon>
        </v-btn>
        <v-btn
          v-bind="buttonProps"
          :value="'none'"
          :class="noneButtonColorClass"
          :disabled="ignoreByDefault || readByItself"
        >
          <v-icon size="x-large">mdi-slash-forward</v-icon>
        </v-btn>
        <v-btn
          v-bind="buttonProps"
          :value="true"
          :class="readButtonColorClass"
          :disabled="ignoreByDefault"
          color="success"
        >
          <v-icon size="x-large" :color="ignoreByDefault ? undefined : value === true ? 'white' : 'success'"
            >mdi-check</v-icon
          >
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import type { Channel } from "@/types";
import { ref, computed, inject, type Ref } from "vue";
import { storeToRefs } from "pinia";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useChannelStore } from "@/stores/useChannelStore";
import { useVChannelStore } from "@/stores/useVChannelStore";

const guildChannel = inject<Ref<Channel>>("guildChannel");

const props = defineProps<{
  channel: Channel;
}>();

const vGuildStore = useVGuildStore();
const channelStore = useChannelStore();
const { vGuild } = storeToRefs(vGuildStore);
const { currentChannel } = storeToRefs(channelStore);

const buttonProps = ref({
  height: 28,
  size: "x-small",
  class: "vcGuildTextChannelReadState_button bg-background",
});

const ignoreByDefault = computed(() => {
  return props.channel.id !== guildChannel?.value.id && props.channel.ignoreByDefault;
});
const readByItself = computed(() => {
  return vGuild.value.read_voice_channel_itself && props.channel.id === currentChannel.value?.id;
});
const value = computed({
  get() {
    if (ignoreByDefault.value) return false;
    return props.channel.readState === null ? "none" : props.channel.readState || false;
  },
  set(newValue: boolean | "none") {
    if (currentChannel.value) {
      const readState = newValue === "none" ? null : newValue;
      useVChannelStore().updateReadChannel(currentChannel.value, props.channel, readState);
    }
  },
});

const tooltipText = computed(() => {
  if (ignoreByDefault.value) return "共通設定で無効のため変更できません";
  if (readByItself.value) return "接続VC内チャンネルを読み上げるがONになっています";
  return undefined;
});
const noneButtonColorClass = computed(() => {
  return { "text-inactive-darken": ignoreByDefault.value || readByItself.value };
});
const readButtonColorClass = computed(() => {
  return { "text-inactive-darken": ignoreByDefault.value };
});
</script>

<style lang="scss" scoped>
$-border: 1px solid rgb(var(--v-theme-surface));

.vcGuildTextChannelReadState_button {
  border-top: $-border;
  border-bottom: $-border;

  &:first-of-type {
    border-left: $-border;
  }

  &:last-of-type {
    border-right: $-border;
  }
}
</style>
