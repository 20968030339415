<template>
  <component :is="iconComponent" v-if="iconComponent" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import DiscordVoiceChannelIcon from "@/components/DiscordVoiceChannelIcon.vue";
import DiscordAnnounceChannelIcon from "@/components/DiscordAnnounceChannelIcon.vue";
import DiscordStageChannelIcon from "@/components/DiscordStageChannelIcon.vue";
import DiscordTextChannelIcon from "@/components/DiscordTextChannelIcon.vue";
import DiscordForumChannelIcon from "@/components/DiscordForumChannelIcon.vue";
import { ChannelTypes } from "@/const";

const props = defineProps<{
  type: ChannelTypes;
}>();

const iconComponentMapping = {
  [ChannelTypes.GUILD_TEXT]: DiscordTextChannelIcon,
  [ChannelTypes.DM]: DiscordTextChannelIcon,
  [ChannelTypes.GUILD_VOICE]: DiscordVoiceChannelIcon,
  [ChannelTypes.GROUP_DM]: DiscordTextChannelIcon,
  [ChannelTypes.GUILD_CATEGORY]: null,
  [ChannelTypes.GUILD_ANNOUNCEMENT]: DiscordAnnounceChannelIcon,
  [ChannelTypes.ANNOUNCEMENT_THREAD]: null,
  [ChannelTypes.PUBLIC_THREAD]: null,
  [ChannelTypes.PRIVATE_THREAD]: null,
  [ChannelTypes.GUILD_STAGE_VOICE]: DiscordStageChannelIcon,
  [ChannelTypes.GUILD_DIRECTORY]: null,
  [ChannelTypes.GUILD_FORUM]: DiscordForumChannelIcon,
  [ChannelTypes.GUILD_MEDIA]: null,
};
const iconComponent = computed(() => iconComponentMapping[props.type]);
</script>
