import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "../stylesheets/application.scss";

import type { RouteLocationNormalized } from "vue-router";
import { createApp, markRaw } from "vue";
import VueScrollTo from "vue-scrollto";
import App from "@/App.vue";
import dashboardMiddleware from "@/middleware/dashboard";
import Vuetify from "@/plugins/vuetify";
import Pinia from "@/plugins/Pinia";
import Router from "@/plugins/Router";

const app = createApp(App);

const scrollOptions = {
  duration: 500,
  easing: "ease",
  force: true,
  cancelable: true,
};

Pinia.use(({ store }) => {
  store.router = markRaw(Router);
});

Router.beforeEach(async (to: RouteLocationNormalized) => {
  if (to.meta?.layout === "LpLayout") {
    if (document.querySelector("#top")) {
      VueScrollTo.scrollTo("#top", { ...scrollOptions, offset: -64 });
    }
  } else if (to.path !== "/404") {
    await dashboardMiddleware(Pinia, to, Router);
  }
});

app.use(Router).use(Pinia).use(Vuetify).use(VueScrollTo, scrollOptions);

Router.isReady().then(() => {
  app.mount("#app");
});
