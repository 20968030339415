<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-toolbar theme="dark">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="text-black" color="cancel" autofocus @click="dialog = false">キャンセル</v-btn>
        <v-btn v-for="action in actions" :key="action.name" :color="action.color" size="large" @click="execute(action)">
          {{ action.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";

interface Action {
  name: string;
  color: string;
  text: string;
}

defineProps<{
  title: string;
  message: string;
  actions: Action[];
}>();
const emit = defineEmits<{
  (e: string): void;
}>();

const dialog = ref(false);
const open = () => (dialog.value = true);
const execute = (action: Action) => {
  emit(action.name);
  dialog.value = false;
};

defineExpose({ open });
</script>
