<template>
  <div class="vcGuildTextChannels" :class="{ 'is-mobile': mobile }">
    <div class="vcGuildTextChannels_header">
      <v-text-field
        v-model="query"
        class="vcGuildTextChannels_search"
        prepend-inner-icon="mdi-magnify"
        persistent-placeholder
        variant="solo"
        hide-details
        :placeholder="placeholder"
      />
    </div>
    <div class="vcGuildTextChannels_list flex-grow-1">
      <template v-if="!isGuildChannel">
        <v-list class="bg-transparent">
          <v-list-item :link="false" title="自動接続">
            <template #append>
              <VcGuildVoiceChannelAutoJoinSwitch :v-channel="currentVChannel" />
            </template>
          </v-list-item>
        </v-list>
        <v-divider class="my-2" />
      </template>
      <VcGuildChannelItems v-if="!isGuildChangeLoading" :channels="textChannels">
        <template #append="{ channel }">
          <VcGuildTextChannelReadState :channel="channel" />
        </template>
      </VcGuildChannelItems>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Channel } from "@/types";
import { ref, computed, inject, watch, type Ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useChannelStore } from "@/stores/useChannelStore";
import { useVChannelStore } from "@/stores/useVChannelStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { searchTextChannels } from "@/utilities/channel";
import { ChannelTypes } from "@/const";

const guildChannel = inject<Ref<Channel>>("guildChannel");

const route = useRoute();
const { mobile } = useDisplay();

const vGuildStore = useVGuildStore();
const channelStore = useChannelStore();
const vChannelStore = useVChannelStore();
const loadingStore = useLoadingStore();
const { vGuild } = storeToRefs(vGuildStore);
const { channels, currentChannel } = storeToRefs(channelStore);
const { vChannels } = storeToRefs(vChannelStore);
const { isGuildChangeLoading } = storeToRefs(loadingStore);

const query = ref("");
const isGuildChannel = computed(() => {
  return currentChannel.value?.id === guildChannel?.value?.id;
});
const ignoreByDefault = (channel: Channel): boolean => {
  return (
    currentChannel.value?.id !== guildChannel?.value?.id &&
    !!guildChannel?.value?.vChannel?.ignore_channel_ids?.includes(channel.id)
  );
};
const calcReadState = (channel: Channel): boolean | null => {
  if (currentChannel.value?.vChannel?.ignore_channel_ids?.includes(channel.id)) return false;
  if (vGuild.value.read_voice_channel_itself && currentChannel.value?.vChannel?.channel_id === channel.id) return true;
  if (currentChannel.value?.vChannel?.channel_ids?.includes(channel.id)) return true;
  return null;
};
const calcReadStateInheritDefault = (channel: Channel): boolean | null => {
  if (ignoreByDefault(channel)) return false;
  if (currentChannel.value?.vChannel?.ignore_channel_ids?.includes(channel.id)) return false;
  if (currentChannel.value?.vChannel?.channel_ids?.includes(channel.id)) return true;
  if (guildChannel?.value?.vChannel?.channel_ids?.includes(channel.id)) return true;
  return null;
};
const calcReadStateInheritDefaultAndParent = (channel: Channel): boolean | null => {
  const readState = calcReadStateInheritDefault(channel);
  if (readState !== null) return readState;
  if (!channel.parent) return null;
  if (
    channel.parent.type === ChannelTypes.GUILD_CATEGORY ||
    channel.parent.type === ChannelTypes.GUILD_FORUM ||
    vGuild.value.inherit_readability_to_thread
  )
    return calcReadStateInheritDefaultAndParent(channel.parent);
  return null;
};
const injectReadState = (channels: Channel[]): Channel[] => {
  return channels.map((channel) => {
    return {
      ...channel,
      readState: calcReadState(channel),
      inheritedReadState: calcReadStateInheritDefaultAndParent(channel),
      ignoreByDefault: ignoreByDefault(channel),
      children: injectReadState(channel.children),
    };
  });
};
const textChannels = computed(() => {
  if (!guildChannel) return [];
  if (!currentChannel.value) return [];
  if (!vChannels.value) return [];

  return injectReadState(searchTextChannels(channels.value, query.value));
});
const placeholder = computed(() => {
  return mobile.value ? "検索（名前またはチャンネルID）" : "チャンネルを検索（名前またはチャンネルID）";
});

const currentVChannel = computed(() => {
  return (
    vChannels.value.find((vChannel) => vChannel.channel_id === currentChannel.value?.id) || {
      channel_id: currentChannel.value?.id,
      auto_join: false,
      channel_ids: [],
      ignore_channel_ids: [],
    }
  );
});

const initialize = () => {
  query.value = "";
};
watch(route, initialize);
initialize();
</script>

<style lang="scss" scoped>
@use "@/stylesheets/mixin.scss";

.vcGuildTextChannels.is-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.vcGuildTextChannels_header {
  margin-top: 32px;
  min-height: 80px;
}

.vcGuildTextChannels_search {
  width: 100%;
  margin-bottom: 8px;
}

.vcGuildTextChannels_list {
  @include mixin.no-scrollbar;

  overflow-y: auto;
  position: relative;

  .is-mobile & {
    flex-grow: 1;
  }
}
</style>
