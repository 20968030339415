<template>
  <v-icon class="vcOpener">{{ icon }}</v-icon>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  isOpen: boolean;
}>();

const icon = computed(() => (props.isOpen ? "mdi-menu-down" : "mdi-menu-right"));
</script>

<style lang="scss" scoped>
.vcOpener {
  margin: 0 -4px 0 -18px;
}
</style>
