import type { AxiosError } from "axios";
import type { Role, VModerator } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const getGuildId = () => useVGuildStore().vGuild.guild_id;

const handleError = (_axiosError: AxiosError) => {
  useSnackbarStore().addDefaultGetError();
};

export const useVModeratorStore = defineStore("v_moderators", {
  state: () => {
    return {
      roles: [] as Role[],
      vModerators: [] as VModerator[],
    };
  },

  actions: {
    async fetchRoles() {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI().get(`/api/v_guilds/${guildId}/roles`).catch(handleError);
      if (!response) return;

      this.roles = response.data;
    },
    async fetchVModerators() {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI().get(`/api/v_guilds/${guildId}/v_moderators`).catch(handleError);
      if (!response) return;

      this.vModerators = response.data;
    },
    async addVModerator(role: Role) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI()
        .post(`/api/v_guilds/${guildId}/v_moderators`, { role_id: role.id })
        .catch(handleError);
      if (!response) return;

      this.vModerators.push(response.data);
      useSnackbarStore().addTopRight({ color: "success", text: "更新しました。" });
    },
    async deleteVModerator(role: Role) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI().delete(`/api/v_guilds/${guildId}/v_moderators/${role.id}`).catch(handleError);
      if (!response) return;

      const index = this.vModerators.findIndex((vModerator) => {
        return vModerator.role_id === role.id;
      });
      if (index >= 0) {
        this.vModerators.splice(index, 1);
        useSnackbarStore().addTopRight({ color: "success", text: "更新しました。" });
      }
    },
  },
});
