interface parseJSONBigintOptions {
  /**
   * @default false
   */
  strict?: boolean | undefined;
  /**
   * @default false
   */
  storeAsString?: boolean | undefined;
  /**
   * @default false
   */
  alwaysParseAsBig?: boolean | undefined;
  /**
   * @default false
   */
  useNativeBigInt?: boolean | undefined;
  /**
   * @default 'error'
   */
  protoAction?: "error" | "ignore" | "preserve" | undefined;
  /**
   * @default 'error'
   */
  constructorAction?: "error" | "ignore" | "preserve" | undefined;
}

export const createJSONBigintParser = (options: parseJSONBigintOptions) => {
  const BigNumber = (x?: any) => {
    throw "BigNumber is not installed.";
  };
  const suspectProtoRx =
    /(?:_|\\u005[Ff])(?:_|\\u005[Ff])(?:p|\\u0070)(?:r|\\u0072)(?:o|\\u006[Ff])(?:t|\\u0074)(?:o|\\u006[Ff])(?:_|\\u005[Ff])(?:_|\\u005[Ff])/;
  const suspectConstructorRx =
    /(?:c|\\u0063)(?:o|\\u006[Ff])(?:n|\\u006[Ee])(?:s|\\u0073)(?:t|\\u0074)(?:r|\\u0072)(?:u|\\u0075)(?:c|\\u0063)(?:t|\\u0074)(?:o|\\u006[Ff])(?:r|\\u0072)/;
  const escapee = {
    '"': '"',
    "\\": "\\",
    "/": "/",
    b: "\b",
    f: "\f",
    n: "\n",
    r: "\r",
    t: "\t",
  };

  const _options = {
    strict: false, // not being strict means do not generate syntax errors for "duplicate key"
    storeAsString: false, // toggles whether the values should be stored as BigNumber (default) or a string
    alwaysParseAsBig: false, // toggles whether all numbers should be Big
    useNativeBigInt: false, // toggles whether to use native BigInt instead of bignumber.js
    protoAction: "error",
    constructorAction: "error",
  };

  if (options !== undefined && options !== null) {
    if (options.strict === true) {
      _options.strict = true;
    }
    if (options.storeAsString === true) {
      _options.storeAsString = true;
    }
    _options.alwaysParseAsBig = options.alwaysParseAsBig === true ? options.alwaysParseAsBig : false;
    _options.useNativeBigInt = options.useNativeBigInt === true ? options.useNativeBigInt : false;

    if (typeof options.constructorAction !== "undefined") {
      if (
        options.constructorAction === "error" ||
        options.constructorAction === "ignore" ||
        options.constructorAction === "preserve"
      ) {
        _options.constructorAction = options.constructorAction;
      } else {
        throw new Error(
          `Incorrect value for constructorAction option, must be "error", "ignore" or undefined but passed ${options.constructorAction}`,
        );
      }
    }

    if (typeof options.protoAction !== "undefined") {
      if (options.protoAction === "error" || options.protoAction === "ignore" || options.protoAction === "preserve") {
        _options.protoAction = options.protoAction;
      } else {
        throw new Error(
          `Incorrect value for protoAction option, must be "error", "ignore" or undefined but passed ${options.protoAction}`,
        );
      }
    }
  }

  let at: number;
  let ch: string;
  let text: string;

  const error = (m: string) => {
    throw { name: "SyntaxError", message: m, at, text };
  };
  const next = (c?: string) => {
    if (c && c !== ch) {
      error(`Expected '${c}' instead of '${ch}'`);
    }

    ch = text.charAt(at);
    at += 1;
    return ch;
  };

  const number = () => {
    let number: number;
    let string: string = "";

    if (ch === "-") {
      string = "-";
      next("-");
    }
    while (ch >= "0" && ch <= "9") {
      string += ch;
      next();
    }
    if (ch === ".") {
      string += ".";
      while (next() && ch >= "0" && ch <= "9") {
        string += ch;
      }
    }
    if (ch === "e" || ch === "E") {
      string += ch;
      next();

      // @ts-ignore
      if (ch === "-" || ch === "+") {
        string += ch;
        next();
      }
      while (ch >= "0" && ch <= "9") {
        string += ch;
        next();
      }
    }
    number = +string;
    if (!isFinite(number)) {
      error("Bad number");
    } else {
      if (Number.isSafeInteger(number))
        return !_options.alwaysParseAsBig ? number : _options.useNativeBigInt ? BigInt(number) : BigNumber(number);
      // Number with fractional part should be treated as number(double) including big integers in scientific notation, i.e 1.79e+308
      else
        return _options.storeAsString
          ? string
          : /[\.eE]/.test(string)
          ? number
          : _options.useNativeBigInt
          ? BigInt(string)
          : BigNumber(string);
    }
  };

  const string = () => {
    let hex: any;
    let i: any;
    let string: any = "";
    let uffff: any;

    // When parsing for string values, we must look for " and \ characters.

    if (ch === '"') {
      let startAt = at;
      while (next()) {
        if (ch === '"') {
          if (at - 1 > startAt) string += text.substring(startAt, at - 1);
          next();
          return string;
        }
        if (ch === "\\") {
          if (at - 1 > startAt) string += text.substring(startAt, at - 1);
          next();
          if (ch === "u") {
            uffff = 0;
            for (i = 0; i < 4; i += 1) {
              hex = parseInt(next(), 16);
              if (!isFinite(hex)) {
                break;
              }
              uffff = uffff * 16 + hex;
            }
            string += String.fromCharCode(uffff);
          } else if (typeof escapee[ch] === "string") {
            string += escapee[ch];
          } else {
            break;
          }
          startAt = at;
        }
      }
    }
    error("Bad string");
  };

  const white = () => {
    while (ch && ch <= " ") {
      next();
    }
  };

  const word = () => {
    switch (ch) {
      case "t":
        next("t");
        next("r");
        next("u");
        next("e");
        return true;
      case "f":
        next("f");
        next("a");
        next("l");
        next("s");
        next("e");
        return false;
      case "n":
        next("n");
        next("u");
        next("l");
        next("l");
        return null;
    }
    error("Unexpected '" + ch + "'");
  };

  const array = () => {
    const array: any[] = [];

    if (ch === "[") {
      next("[");
      white();

      // @ts-ignore
      if (ch === "]") {
        next("]");
        return array; // empty array
      }
      while (ch) {
        array.push(value());
        white();
        // @ts-ignore
        if (ch === "]") {
          next("]");
          return array;
        }
        next(",");
        white();
      }
    }
    error("Bad array");
  };

  const object = () => {
    let key: any;
    const object: any = {};

    if (ch === "{") {
      next("{");
      white();
      // @ts-ignore
      if (ch === "}") {
        next("}");
        return object; // empty object
      }
      while (ch) {
        key = string();
        white();
        next(":");
        if (_options.strict === true && Object.hasOwnProperty.call(object, key)) {
          error('Duplicate key "' + key + '"');
        }

        if (suspectProtoRx.test(key) === true) {
          if (_options.protoAction === "error") {
            error("Object contains forbidden prototype property");
          } else if (_options.protoAction === "ignore") {
            value();
          } else {
            object[key] = value();
          }
        } else if (suspectConstructorRx.test(key) === true) {
          if (_options.constructorAction === "error") {
            error("Object contains forbidden constructor property");
          } else if (_options.constructorAction === "ignore") {
            value();
          } else {
            object[key] = value();
          }
        } else {
          object[key] = value();
        }

        white();
        // @ts-ignore
        if (ch === "}") {
          next("}");
          return object;
        }
        next(",");
        white();
      }
    }
    error("Bad object");
  };

  const value = () => {
    white();
    switch (ch) {
      case "{":
        return object();
      case "[":
        return array();
      case '"':
        return string();
      case "-":
        return number();
      default:
        return ch >= "0" && ch <= "9" ? number() : word();
    }
  };

  return (source: any) => {
    let result: any;

    text = source + "";
    at = 0;
    ch = " ";
    result = value();
    white();
    if (ch) {
      error("Syntax error");
    }

    return result;
  };
};
