import type { AxiosError } from "axios";
import type { VMember } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const getGuildId = () => useVGuildStore().vGuild.guild_id;

const handleError = (_axiosError: AxiosError) => {
  useSnackbarStore().addDefaultGetError();
};

const vMemberDefault = {
  call_by_nickname: null,
  volume: null,
  speed: null,
  pitch: null,
  speaker: null,
  message_format: null,
};

export const useVMemberStore = defineStore("v_members", {
  state: () => {
    return {
      vMember: undefined as VMember | undefined,
    };
  },

  actions: {
    clear() {
      this.vMember = undefined;
    },
    async fetchVMember(userId: string | bigint) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI().get(`/api/v_guilds/${guildId}/v_members/${userId}`).catch(handleError);
      if (!response) return;

      this.vMember = response.data ? { ...vMemberDefault, ...response.data } : undefined;
    },
  },
});
