<template>
  <v-list-item
    class="vcGuildChannelItem"
    :class="[indentClass, textColorClass, transparentClass, channelTypeClass]"
    :title="channel.name"
    :height="height"
    :link="link"
    :variant="variant"
    :density="density"
    :active="channel.vChannel?.channel_id === currentChannel?.id"
    @click="onClick"
  >
    <template #prepend>
      <VcOpener v-if="isParent" :is-open="isOpen" />
      <VcGuildChannelIcon class="mr-1" :class="iconClass" :type="channel.type" />
      <div v-if="isThread" class="VcGuildChannelItem_spineContainer" :class="threadClass">
        <div v-if="isLastThread" class="VcGuildChannelItem_spine" />
        <div v-else class="VcGuildChannelItem_spineBorder" />
      </div>
    </template>
    <template #append>
      <slot name="line" :channel="channel" />
    </template>
  </v-list-item>
  <template v-if="isOpen">
    <VcGuildChannelItem
      v-for="child in channel.children"
      :key="child.id"
      :channel="child"
      :height="props.height"
      :link="props.link"
    >
      <template #line="{ channel: lineChannel }">
        <slot name="line" :channel="lineChannel" />
      </template>
    </VcGuildChannelItem>
  </template>
</template>

<script setup lang="ts">
import type { Channel } from "@/types";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useChannelStore } from "@/stores/useChannelStore";
import { isThread as _isThread } from "@/utilities/channel";
import { ChannelTypes } from "@/const";

const vGuildStore = useVGuildStore();
const channelStore = useChannelStore();
const { vGuild } = storeToRefs(vGuildStore);
const { currentChannel } = storeToRefs(channelStore);

const props = defineProps<{
  channel: Channel;
  height?: number | string;
  link?: boolean;
  transparent?: boolean;
}>();
defineSlots<{
  line(props: { channel: Channel }): never;
}>();

const isCategory = computed(() => props.channel.type === ChannelTypes.GUILD_CATEGORY);
const isParent = computed(() => !!props.channel.children.length);
const indentClass = computed(() => {
  return props.channel.parent ? (props.channel.parent.parent ? "ml-8" : "ml-4") : "";
});
const readByItself = computed(() => {
  return vGuild.value.read_voice_channel_itself && props.channel.id === currentChannel.value?.id;
});
const iconClass = computed(() => ({
  "text-info": readByItself.value && props.channel.id !== vGuild.value.guild_id,
}));
const channelTypeClass = computed(() => {
  return isCategory.value ? "is-category" : "";
});
const height = computed(() => (isCategory.value ? undefined : props.height));
const link = computed(() => props.link || isParent.value);
const variant = computed(() => (link.value ? undefined : "text"));
const density = computed(() => (link.value ? undefined : "compact"));
const isThread = computed(() => _isThread(props.channel));
const isLastThread = computed(() => {
  if (!props.channel.parent) return false;
  return (
    props.channel.parent.children.findIndex((child) => child.id === props.channel.id) ===
    props.channel.parent.children.length - 1
  );
});
const threadClass = computed(() => {
  return {
    "is-inheritThread":
      vGuild.value.inherit_readability_to_thread && props.channel.parent?.type !== ChannelTypes.GUILD_FORUM,
    "is-notInherit":
      !vGuild.value.inherit_readability_to_thread && props.channel.parent?.type !== ChannelTypes.GUILD_FORUM,
  };
});
const textColorClass = computed(() => {
  if (props.channel?.readState === false) return "text-danger";
  if (props.channel?.inheritedReadState === false) return "text-danger-lighten";
  if (props.channel?.readState === true) return "text-success";
  if (props.channel?.inheritedReadState === true) return "text-success-lighten";
  return undefined;
});
const transparentClass = computed(() => {
  return props.transparent ? "is-transparent" : "";
});

const isOpen = ref(true);

const onClick = () => {
  if (isParent.value) {
    isOpen.value = !isOpen.value;
  } else if (props.channel.vChannel) {
    channelStore.updateCurrentChannel(props.channel);
  }
};
</script>

<style lang="scss" scoped>
.vcGuildChannelItem :deep(.v-list-item__spacer) {
  display: none;
}

.vcGuildChannelItem.is-transparent :deep(.v-list-item__overlay) {
  background: transparent;
}

.VcGuildChannelItem_spineContainer {
  position: relative;
  width: 12px;
}

.VcGuildChannelItem_spineBorder {
  position: absolute;
  background: rgb(var(--v-theme-inactive));
  border-radius: 2px;
  width: 2px;
  top: -22px;
  bottom: 0;
  height: 45px;
  left: -8px;

  .is-inheritThread & {
    border-color: rgb(var(--v-theme-warning));
  }

  .is-notInherit & {
    width: 1px;
  }
}

.VcGuildChannelItem_spine {
  position: absolute;
  border-radius: 4px;
  width: 12px;
  height: 24px;
  top: -22px;
  right: 8px;
  border-left: 2px solid rgb(var(--v-theme-inactive));
  border-bottom: 2px solid rgb(var(--v-theme-inactive));
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;

  .is-inheritThread & {
    border-color: rgb(var(--v-theme-warning));
  }

  .is-notInherit & {
    border-width: 1px;
  }
}
</style>
