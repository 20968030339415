<template>
  <v-chip class="vc-setting--autosave" size="x-small" label color="cyan-accent-3" prepend-icon="mdi-sync" :rounded="0">
    自動保存
  </v-chip>
</template>

<style lang="scss">
.vc-setting--autosave {
  position: absolute;
  top: 0;
  left: 0;

  &.v-chip.v-chip--density-default {
    height: 16px;
  }
}
</style>
