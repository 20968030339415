import type { User } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";

export const useUserStore = defineStore("users", {
  state: () => {
    return {
      user: undefined as User | undefined,
      drawer: false,
    };
  },

  actions: {
    async fetchUser() {
      const api = useAPI();
      await api
        .get("/api/user")
        .then((response) => (this.user = response.data))
        .catch(() => this.$reset());
    },
    setDrawer(newValue: boolean) {
      this.drawer = newValue;
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
});
