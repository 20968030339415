export const ChannelTypes = {
  GUILD_TEXT: 0,
  DM: 1,
  GUILD_VOICE: 2,
  GROUP_DM: 3,
  GUILD_CATEGORY: 4,
  GUILD_ANNOUNCEMENT: 5,
  ANNOUNCEMENT_THREAD: 10,
  PUBLIC_THREAD: 11,
  PRIVATE_THREAD: 12,
  GUILD_STAGE_VOICE: 13,
  GUILD_DIRECTORY: 14,
  GUILD_FORUM: 15,
  GUILD_MEDIA: 16,
} as const;
export type ChannelTypes = (typeof ChannelTypes)[keyof typeof ChannelTypes];

export const ReadNameSkipIntervalType = {
  ZERO: "zero",
  TEN_SECONDS: "ten_seconds",
  THIRTY_SECONDS: "thirty_seconds",
  FIVE_MINUTES: "five_minutes",
  INFINITY: "infinity",
} as const;
export type ReadNameSkipIntervalType = (typeof ReadNameSkipIntervalType)[keyof typeof ReadNameSkipIntervalType];

export const DiscordPermission = {
  ADMINISTRATOR: 1 << 3,
  MANAGE_GUILD: 1 << 5,
} as const;
export type DiscordPermission = (typeof DiscordPermission)[keyof typeof DiscordPermission];

export const CMAP_URL = import.meta.env.DEV ? "../node_modules/pdfjs-dist/cmaps/" : "./assets/cmaps/";
