import type { Channel } from "@/types";
import { ChannelTypes } from "@/const";

export const isParent = (channel: Channel) => !!channel.children.length;
export const isCategory = (channel: Channel) => channel.type === ChannelTypes.GUILD_CATEGORY;
export const isThread = (channel: Channel) =>
  channel.type === ChannelTypes.ANNOUNCEMENT_THREAD ||
  channel.type === ChannelTypes.PUBLIC_THREAD ||
  channel.type === ChannelTypes.PRIVATE_THREAD;
export const isVoiceChannel = (channel: Channel) =>
  channel.type === ChannelTypes.GUILD_VOICE || channel.type === ChannelTypes.GUILD_STAGE_VOICE;
export const isTextChannel = (channel: Channel) =>
  channel.type === ChannelTypes.GUILD_TEXT ||
  channel.type === ChannelTypes.GUILD_VOICE ||
  channel.type === ChannelTypes.GUILD_CATEGORY ||
  channel.type === ChannelTypes.GUILD_ANNOUNCEMENT ||
  channel.type === ChannelTypes.ANNOUNCEMENT_THREAD ||
  channel.type === ChannelTypes.PUBLIC_THREAD ||
  channel.type === ChannelTypes.PRIVATE_THREAD ||
  channel.type === ChannelTypes.GUILD_STAGE_VOICE ||
  channel.type === ChannelTypes.GUILD_DIRECTORY ||
  channel.type === ChannelTypes.GUILD_FORUM ||
  channel.type === ChannelTypes.GUILD_MEDIA;

const buildChannelTree = (channels: Channel[], f: (channel: Channel) => boolean): Channel[] => {
  const tree: Channel[] = [];
  let newChannel: Channel | undefined;

  channels.forEach((channel) => {
    newChannel = undefined;

    if (channel.children.length) {
      const children = buildChannelTree(channel.children, f);
      if (children.length) {
        newChannel = { ...channel, children };
      }
    }
    if (!newChannel && f(channel)) {
      newChannel = { ...channel, children: [] };
    }
    if (newChannel) {
      newChannel.children.forEach((child) => (child.parent = newChannel));
      tree.push(newChannel);
    }
  });

  return tree;
};

const buildSearchVoiceChannel =
  (query: string) =>
  (channel: Channel): boolean => {
    return isVoiceChannel(channel) && (channel.name?.includes(query) || channel.id === query);
  };

const buildSearchTextChannel =
  (query: string) =>
  (channel: Channel): boolean => {
    return isTextChannel(channel) && (channel.name?.includes(query) || channel.id === query);
  };

export const searchVoiceChannels = (channels: Channel[], query: string) => {
  return buildChannelTree(channels, buildSearchVoiceChannel(query.trim()));
};

export const searchTextChannels = (channels: Channel[], query: string) => {
  return buildChannelTree(channels, buildSearchTextChannel(query.trim()));
};
