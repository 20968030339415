import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", {
  state: () => {
    return {
      isSetupLoading: true,
      isGuildChangeLoading: false,
    };
  },

  actions: {
    isSetupLoadingDone() {
      this.isSetupLoading = false;
    },
    setIsGuildChangeLoading(isLoading: boolean) {
      this.isGuildChangeLoading = isLoading;
    },
  },
});
