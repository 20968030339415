import { defineStore } from "pinia";

interface Snackbar {
  id: number;
  color: string;
  text: string;
  location?: "top right";
  locationStrategy?: "connected";
  timeout?: number;
  contentClass?: string;
}

export const useSnackbarStore = defineStore("snackbar", {
  state: () => {
    return {
      snackbars: [] as Snackbar[],
      snackbarId: 0,
    };
  },

  actions: {
    addTopRight(snackbar: Pick<Snackbar, "color" | "text" | "timeout">) {
      this.snackbars.push({
        timeout: 1000,
        id: this.snackbarId++,
        locationStrategy: "connected",
        ...snackbar,
        location: "top right",
      });
    },
    addDefaultGetError() {
      this.addTopRight({
        color: "danger",
        text: "データの取得に失敗しました。時間をおいて再度試しても改善しない場合はお問合せください。",
        timeout: -1,
      });
    },
    addDefaultUpdateError() {
      this.addTopRight({
        color: "danger",
        text: "データの更新に失敗しました。時間をおいて再度試しても改善しない場合はお問合せください。",
        timeout: -1,
      });
    },
    remove(id: number) {
      const index = this.snackbars.findIndex((s) => s.id === id);
      this.snackbars.splice(index, 1);
    },
  },
});
