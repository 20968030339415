<template>
  <div class="d-flex align-center mb-2">
    <v-icon v-if="onlySubscribed" class="mr-1" icon="mdi-crown" color="subscribed-primary" />
    <v-label :text="label" />
    <v-spacer />
    <v-btn-toggle v-model="value" mandatory shaped>
      <v-btn prepend-icon="mdi-sync" color="info" :disabled="disabled && value === 1">
        {{ mobile ? "同期" : "サーバーに同期" }}
      </v-btn>
      <v-btn prepend-icon="mdi-account" color="subscribed-primary" :disabled="disabled && value === 0">
        {{ mobile ? "個別" : "ユーザー設定" }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { useVGuildStore } from "@/stores/useVGuildStore";

const { mobile } = useDisplay();

const props = defineProps<{
  modelValue: NonNullable<unknown> | null;
  label: string;
  initialValue: NonNullable<unknown> | null;
  onlySubscribed?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: NonNullable<unknown> | null): void;
}>();

const vGuildStore = useVGuildStore();
const disabled = computed(() => {
  return props.disabled || (props.onlySubscribed && !vGuildStore.isSubscribed);
});

const value = computed({
  get(): number {
    return (props.onlySubscribed && !vGuildStore.isSubscribed) || props.modelValue === null ? 0 : 1;
  },
  set(newValue: number) {
    if (newValue === 0) {
      emit("update:modelValue", null);
    } else {
      emit("update:modelValue", props.initialValue);
    }
  },
});
</script>
