import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { createJSONBigintParser } from "@/utilities/createJSONBigintParser";

const { siteUrl } = useRuntimeConfig();

type APIResponse = Promise<AxiosResponse>;

export const useAPI = (options = {}) => {
  const instance = axios.create({
    baseURL: siteUrl,
    timeout: 5000,
    ...options,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        window.location.href = siteUrl;
      }
      if (error.response?.status === 403) {
        useSnackbarStore().addTopRight({
          color: "danger",
          text: "Discordからのデータ取得に失敗しました。一度BOTをサーバーから追放し、再度招待すると改善する場合があります。",
        });
        return;
      }
      throw error;
    },
  );

  const JSONBigintParser = createJSONBigintParser({ storeAsString: true, useNativeBigInt: true });
  const transformResponse = (data: NonNullable<unknown>) => JSONBigintParser(data);

  return {
    get: (url: string, config?: AxiosRequestConfig): APIResponse => {
      return instance.get(url, { ...config, transformResponse });
    },
    post: (url: string, data?: NonNullable<unknown>, config?: AxiosRequestConfig | undefined): APIResponse => {
      return instance.post(url, data, { ...config, transformResponse });
    },
    patch: (url: string, data?: NonNullable<unknown>, config?: AxiosRequestConfig | undefined): APIResponse => {
      return instance.patch(url, data, { ...config, transformResponse });
    },
    delete: (url: string, config?: AxiosRequestConfig | undefined): APIResponse => {
      return instance.delete(url, { ...config, transformResponse });
    },
  };
};
