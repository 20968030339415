<template>
  <div class="p-index" :class="{ 'is-mobile pa-2': mobile }">
    <div class="p-index__hero" :class="{ 'flex-wrap': mobile }">
      <div class="flex-grow-1">
        <div :class="{ 'd-flex justify-center': mobile }">
          <h1 class="p-index__title mb-4">さあ、<br />新しい声を手に入れよう。</h1>
        </div>
        <div class="p-index__subttile mb-2" :class="{ 'd-flex justify-center text-center': mobile }">
          <div>
            <a v-scroll-to="{ el: '#aivoice', offset: -80 }" href="#">A.I.VOICE<sup>※</sup></a
            >によるもう一つの「声」<br />
            コミュニティーを盛り上げて<br />
            ボイスチャットを楽しくする<br />
            今までにない読み上げBOT<br />
          </div>
        </div>
        <div id="aivoice" class="text-caption text-grey-darken-1 mb-4" :class="{ 'text-center': mobile }">
          ※A.I.VOICEは株式会社エーアイの登録商標です。<br v-if="mobile" />
          <a
            class="p-index__text-underline text-grey-darken-1"
            href="https://aivoice.jp/about/"
            target="_blank"
            rel="noreferrer noopener"
          >
            A.I.VOICEとは（外部サイト）<v-icon>mdi-open-in-new</v-icon>
          </a>
        </div>
        <div v-if="mobile" class="d-flex justify-center">
          <v-img :src="yukariUrl" height="360" width="360" />
        </div>
        <div class="d-flex" :class="{ 'flex-wrap p-index__gap justify-center': mobile }">
          <v-btn
            class="p-index__btn mr-2"
            color="discord-primary"
            target="_blank"
            rel="noreferrer noopener"
            :href="inviteUrl"
          >
            <v-img src="/icon_clyde_white_RGB.svg" width="24" class="mr-2" />
            VOISCORDをサーバーに招待
          </v-btn>
          <v-btn v-if="user" class="p-index__btn" color="grey-darken-2" to="/dashboard"> ダッシュボード </v-btn>
          <v-btn v-else v-scroll-to="{ el: '#features' }" class="p-index__btn" color="grey-darken-2" href="#">
            機能を見る
          </v-btn>
        </div>
      </div>
      <v-img v-if="!mobile" :src="yukariUrl" height="360" width="360" />
    </div>
    <div id="features" class="d-flex justify-center">
      <div class="p-index__section-title">機能</div>
    </div>
    <!-- <div class="p-index__feature" :class="{ 'flex-wrap': mobile }">
      <div class="p-index__feature-description">
        <div class="p-index__feature-title">
          複数BOTに対応
          <span class="text-body-1 text-grey-darken-1 mt-2">※有料プラン限定</span>
        </div>
        <div>
          6つのBOTをいつでも同時に使用可能。<br />
          <br />
          さらに、先行公開機能を備えた<br />
          <a></a>
          <b class="text-nightly">VOISCORD NIGHTLY</b> を含めて<br />
          最大 <b>7つ</b> のBOTで Discord ライフをサポートします
        </div>
      </div>
      <v-skeleton-loader type="button" height="300" min-height="300" width="400" min-width="400" :class="{ 'w-100': mobile }" />
    </div> -->
    <div class="p-index__feature" :class="{ 'flex-wrap': mobile }">
      <div class="p-index__feature-description">
        <div class="p-index__feature-title">Webダッシュボード</div>
        <div>
          全ての設定をWebダッシュボードから変更可能。<br />
          <br />
          VCごとに読み上げ対象チャンネルを紐づけるなど<br />
          細かい設定に対応<br />
        </div>
      </div>
      <v-img :src="featureCustomizeUrl" height="360" width="360" />
      <!-- <v-skeleton-loader
        type="button"
        height="300"
        min-height="300"
        width="400"
        min-width="400"
        :class="{ 'w-100': mobile }"
      /> -->
    </div>
    <div class="p-index__feature" :class="{ 'flex-wrap': mobile }">
      <div class="p-index__feature-description">
        <div class="p-index__feature-title">自分好みにカスタマイズ</div>
        <div>
          多彩な設定項目を用意しました。
          <br />
          <div class="d-inline-block text-left">
            ・自動参加機能<br />
            ・サーバー辞書<br />
            ・メンバー別の読み上げ設定<br />
            ・etc...<br />
          </div>
          <br />
          もちろん、 <b>設定なしでもすぐに</b> ご利用いただけます
        </div>
      </div>
      <v-img :src="featurePreferencesUrl" height="360" width="360" />
      <!-- <v-skeleton-loader
        type="button"
        height="300"
        min-height="300"
        width="400"
        min-width="400"
        :class="{ 'w-100': mobile }"
      /> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { VSkeletonLoader } from "vuetify/labs/VSkeletonLoader";
import yukariUrl from "@/assets/images/yukari.png";
import featurePreferencesUrl from "@/assets/images/feature_preferences.svg";
import featureCustomizeUrl from "@/assets/images/feature_customize.svg";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useUserStore } from "@/stores/useUserStore";

const { discordOauthClientId, discordBotPermissions } = useRuntimeConfig();

const { mobile } = useDisplay();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const inviteUrl = computed(() => {
  const params = new URLSearchParams([
    ["client_id", discordOauthClientId],
    ["permissions", discordBotPermissions],
    ["scope", "bot"],
  ]);
  return `https://discord.com/oauth2/authorize?${params}`;
});
</script>

<style lang="scss" scoped>
@mixin mobile {
  .is-mobile &,
  &.is-mobile {
    @content;
  }
}

.p-index {
  min-width: 860px;
  width: 860px;
  margin: 0 auto;

  @include mobile {
    min-width: 100%;
    width: 100%;
  }
}

.p-index__hero {
  display: flex;
  height: calc(100dvh - 64px - 80px);
  align-items: center;

  @include mobile {
    height: calc(100dvh - 64px);
  }
}

.p-index__title {
  font-size: 40px;

  @include mobile {
    font-size: 30px;
  }
}

.p-index__subttile {
  font-size: 20px;

  @include mobile {
    font-size: 16px;
  }
}

.p-index__text-underline {
  text-decoration: underline;
}

.p-index__btn {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #fff;
  padding: 0 16px;
  outline: none;
  unicode-bidi: plaintext;
  text-decoration: none;
  gap: 8px;

  &.v-btn.v-btn--density-default {
    height: 48px;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
  }
}

.p-index__gap {
  gap: 8px;
}

.p-index__section-title {
  display: inline-block;
  font-size: 36px;
  padding: 0 8px;
  margin-bottom: 64px;
  border-bottom: 2px solid #fff;

  @include mobile {
    margin-top: 32px;
  }
}

.p-index__feature {
  font-size: 16px;
  height: 400px;
  display: flex;
  align-items: center;
  line-height: 200%;

  @include mobile {
    text-align: center;
    height: auto;
    margin-bottom: 64px;
  }
}

.p-index__feature-description {
  flex-grow: 1;
  padding: 8px 16px;
}

.p-index__feature-title {
  font-size: 30px;
  margin: -8px -16px 16px -16px;
}
</style>
