import type { Guild, VGuild } from "@/types";
import { defineStore } from "pinia";
import { useVGuildStore } from "./useVGuildStore";

interface Menu {
  title: string;
  to: string;
  icon: string;
  name: string;
}

const memberMenu = (guildId: string) => ({
  title: "メンバー設定",
  icon: "mdi-account-multiple",
  to: `/server/${guildId}/member`,
  name: "member",
});
const guildMenus = (guildId: string) => [
  {
    title: "一般設定",
    icon: "mdi-cog",
    to: `/server/${guildId}/general`,
    name: "general",
  },
  memberMenu(guildId),
  {
    title: "チャンネル設定",
    icon: "mdi-format-list-bulleted",
    to: `/server/${guildId}/channel`,
    name: "channel",
  },
  {
    title: "辞書設定",
    icon: "mdi-furigana-horizontal",
    to: `/server/${guildId}/dictionary`,
    name: "dictionary",
  },
  {
    title: "モデレーター設定",
    icon: "mdi-wrench",
    to: `/server/${guildId}/moderator`,
    name: "moderator",
  },
];
const noGuildMenu = [
  {
    title: "サーバーを選択",
    icon: "mdi-home",
    to: "/dashboard",
    name: "dashboard",
  },
];

export const useMenuStore = defineStore("menus", {
  state: () => {
    return {
      menus: [] as Menu[],
    };
  },

  actions: {
    async setMenu(guild?: Guild, vGuild?: VGuild) {
      if (!guild) {
        this.menus = noGuildMenu;
      } else if (guild.status === "not_managed_joined") {
        if (vGuild?.is_moderator) {
          this.menus = guildMenus(guild.id);
        } else {
          this.menus = [memberMenu(guild.id)];
        }
      } else {
        this.menus = guildMenus(guild.id);
      }
    },
    unsetMenu() {
      this.menus = [];
    },
  },
});
