<template>
  <v-card class="vc-setting" flat>
    <VcSettingAutoSaveChip v-if="!field" />
    <v-card-text>
      <div class="d-flex align-center">
        <v-label class="vc-setting__label mr-auto" :text="label" />
        <v-btn
          color="grey-darken-3"
          append-icon="mdi-crown"
          :prepend-icon="field ? 'mdi-menu-down' : 'mdi-menu-right'"
          @click="toggleField"
        >
          高度な設定
          <template #append>
            <v-icon color="subscribed-primary" />
          </template>
        </v-btn>
      </div>
      <div v-if="!field" class="d-flex align-center">
        <v-label class="vc-setting__label ml-4 mr-auto" :text="dynamicFlgLabel" />
        <v-switch v-model="dynamicFlg" class="flex-grow-0" color="primary" hide-details inset :disabled="disabled" />
      </div>
      <div v-if="!field && useAutoSaveSection">
        <slot />
      </div>
      <div v-if="field" class="mt-4">
        <v-text-field
          v-model="messageFormat"
          variant="outlined"
          density="compact"
          hide-details
          single-line
          :disabled="disabled || !vGuildStore.isSubscribed"
        />
        <div class="d-flex align-center flex-wrap mt-4 float-left" :class="{ 'mb-4': mobile }">
          <div class="d-inline-block mb-1">次のフラグを使用できます:</div>
          <div class="d-inline-block">
            <v-chip
              v-for="variable in basicVariables"
              :key="variable"
              class="ml-2"
              variant="outlined"
              size="x-small"
              prepend-icon="mdi-check"
            >
              <template #prepend>
                <v-icon color="success" />
              </template>
              {{ variable }}
            </v-chip>
            <v-chip
              v-for="variable in onlySubscribedVariables"
              :key="variable"
              class="ml-2"
              :color="vGuildStore.isSubscribed ? 'white' : 'grey-darken-2'"
              variant="outlined"
              size="x-small"
              :prepend-icon="vGuildStore.isSubscribed ? 'mdi-check' : 'mdi-crown'"
            >
              <template #prepend>
                <v-icon :color="vGuildStore.isSubscribed ? 'subscribed-primary' : 'grey-darken-2'" />
              </template>
              {{ variable }}
            </v-chip>
          </div>
        </div>
        <div class="mt-4 float-right">
          <v-btn class="mr-1 text-black" size="small" color="cancel" :disabled="disabled || !isChanged" @click="reset"
            >戻す</v-btn
          >
          <v-btn size="small" color="secondary" :disabled="disabled || !canSave" @click="save">保存</v-btn>
        </div>
      </div>
      <div class="clear" />
      <div v-if="field && useAutoSaveSection" class="position-relative">
        <VcSettingAutoSaveChip />
        <v-divider class="my-4" />
        <slot />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useVGuildStore } from "@/stores/useVGuildStore";

const { mobile } = useDisplay();
const route = useRoute();

const vGuildStore = useVGuildStore();

const props = defineProps<{
  modelValue: string;
  label: string;
  dynamicFlgLabel: string;
  dynamicFormatOn: string;
  dynamicFormatOff: string;
  dynamicFormatRegexp: RegExp;
  basicVariables: string[];
  onlySubscribedVariables: string[];
  disabled?: boolean;
  useAutoSaveSection?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: string): void;
}>();

const field = ref(false);

const initialize = () => {
  field.value =
    !(props.modelValue === props.dynamicFormatOn) &&
    !(props.modelValue === props.dynamicFormatOff) &&
    vGuildStore.isSubscribed;
};
initialize();

const toggleField = () => (field.value = !field.value);

const allowVariables = computed(() => {
  return vGuildStore.isSubscribed ? [...props.basicVariables, ...props.onlySubscribedVariables] : props.basicVariables;
});

const calcDefaultFormat = (v: boolean): string => (v ? props.dynamicFormatOn : props.dynamicFormatOff);
const calcIsDynamicFlg = (s: string): boolean => props.dynamicFormatRegexp.test(s);

const initialValue = computed(() => {
  return vGuildStore.isSubscribed ? props.modelValue : calcDefaultFormat(calcIsDynamicFlg(props.modelValue));
});
const messageFormat = ref(initialValue.value);

watch(initialValue, () => {
  messageFormat.value = initialValue.value;
});
watch(route, initialize);

const isValid = computed(() => {
  const startParenCount = [...messageFormat.value.matchAll(/\{\{/g)].length;
  const endParenCount = [...messageFormat.value.matchAll(/\}\}/g)].length;
  if (startParenCount !== endParenCount) return false;

  const matches = messageFormat.value.matchAll(/\{\{(.*?)\}\}/g);
  const variables = Array.from(matches, (m) => m[1]);
  return variables.every((v) => allowVariables.value.includes(v));
});
const isChanged = computed(() => messageFormat.value !== initialValue.value);
const canSave = computed(() => isValid.value && isChanged.value);

const reset = () => {
  messageFormat.value = props.modelValue;
};
const save = () => {
  if (!isValid.value) return;
  if (!isChanged.value) return;

  emit("update:modelValue", messageFormat.value);
};
const dynamicFlg = computed({
  get(): boolean {
    return calcIsDynamicFlg(messageFormat.value);
  },
  set(newValue: boolean) {
    messageFormat.value = calcDefaultFormat(newValue);
    if (!field.value) {
      save();
    }
  },
});
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}
</style>
