<template>
  <div class="tokusho-content">
    <h1 class="tokusho-title">特定商取引法に基づく表記</h1>
    <div class="tokusho-table">
      <table>
        <caption class="tokusho-caption">
          <p>*1 スパムメール防止のため、メールアドレスのアットマークを☆に置換しています。</p>
        </caption>

        <tr>
          <td>販売業者</td>
          <td>HandyWeb Lab. 江間俊太郎</td>
        </tr>
        <tr>
          <td>運営統括責任者</td>
          <td>江間俊太郎</td>
        </tr>
        <tr>
          <td>郵便番号</td>
          <td>〒150-0043</td>
        </tr>
        <tr>
          <td>所在地</td>
          <td>神奈川県川崎市多摩区菅稲田堤2丁目1番地60号</td>
        </tr>
        <tr>
          <td>電話番号</td>
          <td>090-9237-0107</td>
        </tr>
        <tr>
          <td>メールアドレス</td>
          <td>contact☆voiscord.net *1</td>
        </tr>
        <tr>
          <td>商品代金以外に必要な料金</td>
          <td>なし</td>
        </tr>
        <tr>
          <td>引渡し時期</td>
          <td>お支払い手続き完了後、直ちにご利用いただけます。</td>
        </tr>
        <tr>
          <td>返品について</td>
          <td>サービスの性質上、返品・返金はお受けしておりません。</td>
        </tr>
        <tr>
          <td>支払方法</td>
          <td>■ カード決済</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style>
.tokusho-title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tokusho-content {
  color: #ffffff;
}

.tokusho-table {
  width: 100%;
  border-collapse: collapse;
}

.tokusho-caption {
  caption-side: bottom;
  text-align: left;
}
</style>
