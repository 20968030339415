<template>
  <v-text-field
    class="vc-setting-number-input input--hide-spin"
    :model-value="value"
    :min="min"
    :max="max"
    :step="1"
    :disabled="disabled"
    type="number"
    variant="outlined"
    density="compact"
    hide-details
    single-line
    @blur="onBlur"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useClamp } from "@/composable/useLodash";

const props = defineProps<{
  modelValue: number;
  min: number;
  max: number;
  disabled: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: number): void;
  (e: "blur"): void;
}>();

const value = computed({
  get(): number {
    return props.modelValue;
  },
  set(newValue: number) {
    emit("update:modelValue", useClamp(newValue, props.min, props.max));
  },
});
const onBlur = ({ target }: Event) => {
  if (!(target instanceof HTMLInputElement)) return;

  const newValue = parseInt(target.value, 10);
  target.value = useClamp(newValue, props.min, props.max).toString();
  value.value = newValue;
};
</script>

<style lang="scss" scoped>
.vc-setting-number-input {
  flex: 0 1 auto;
  width: 48px;
}

.vc-setting-number-input :deep(input) {
  text-align: right;
  padding: 8px;
}

.v-field__input {
  .vc-setting-number-input &::-webkit-outer-spin-button,
  .vc-setting-number-input &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .vc-setting-number-input & {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
</style>
