<template>
  <div v-if="guild && !guild.bot_joined" class="d-flex h-100 w-100 align-center justify-center pt-6">
    <VcDiscordInviteButton :href="inviteUrl" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useGuildStore } from "@/stores/useGuildStore";

const { discordOauthClientId, discordBotPermissions } = useRuntimeConfig();

const guildStore = useGuildStore();

const { guild } = storeToRefs(guildStore);

const inviteUrl = computed(() => {
  const params = new URLSearchParams([
    ["client_id", discordOauthClientId],
    ["permissions", discordBotPermissions],
    ["scope", "bot"],
    ["guild_id", guild.value?.id],
  ]);
  return `https://discord.com/oauth2/authorize?${params}`;
});
</script>
