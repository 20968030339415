<template>
  <v-switch
    v-model="autoJoin"
    :class="{ 'text-inactive': !autoJoin }"
    color="success"
    hide-details
    inset
    :disabled="disabled"
    @click.stop
  />
</template>

<script setup lang="ts">
import type { Channel, VChannel } from "@/types";
import { computed } from "vue";
import { useVChannelStore } from "@/stores/useVChannelStore";

const props = defineProps<{
  channel?: Channel;
  vChannel?: VChannel;
  disabled?: boolean;
}>();

const vChannelStore = useVChannelStore();

const autoJoin = computed({
  get() {
    if (props.vChannel) {
      return props.vChannel.auto_join;
    } else if (props.channel) {
      return props.channel.vChannel?.auto_join || false;
    } else {
      return false;
    }
  },
  set(newValue: boolean) {
    if (props.vChannel) {
      vChannelStore.updateAutoJoin(props.vChannel.channel_id, newValue);
    } else if (props.channel) {
      vChannelStore.updateAutoJoin(props.channel.id, newValue);
    }
  },
});
</script>
