<template>
  <div class="ma-4">
    <div class="text-h3 text-center mb-10">よくあるお問い合わせ</div>
    <template v-for="group in questionGroup" class="no-list">
      <div class="text-h4 mb-1 mt-10" v-html="group.kind"></div>
      <ul>
        <li v-for="question in group.questions" class="faq-card">
          <div class="faq-card-header" @click="toggleAnswer(question)">
            <h3>{{ question.title }}</h3>
            <span class="faq-arrow-icon" :class="{ 'faq-arrow-rotate': question.open }">&#9660;</span>
          </div>
          <div v-if="question.open" class="faq-card-content">
            <p v-html="question.answer"></p>
          </div>
        </li>
      </ul>
    </template>

    <div class="text-h5 text-center mb-15 mt-15 pb-15 pt-15">
      よくある質問を見ても改善されない場合はサポートサーバーで質問することができます。
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const questionGroup = ref([
  {
    kind: "一般",
    questions: [
      {
        title: "VOISCORDの使い方を教えて下さい",
        answer: "使い方ページをご覧下さい。",
        open: false,
      },
      {
        title: "VOISCORD (NIGHTLY) の招待の仕方を教えて下さい。",
        answer: "VOISCORDまたはNIGHTLYのWEBダッシュボードから未導入サーバーを選択し、招待ボタンを押してください。",
        open: false,
      },
      {
        title: "コマンドとは",
        answer:
          "コマンドとはVOISCORDを操作するための命令のことです。<br/>\
                全てのコマンドは`/`から始まる命令文を入力することで実行できます。<br/>\
                コマンドの一覧は使い方ページをご覧下さい。<br/>\
                また、コマンドでできることはWEBからも操作できます。",
        open: false,
      },
      {
        title: "VOISCORDが喋りません",
        answer:
          "【入退室アナウンスは読み上げる場合】<br/>\
                読み上げ対象のチャンネルが有効化されていない可能性があります。<br/>\
                `/vc link-global `や`/vc link`コマンドでチャンネルを有効化して下さい。<br/>\
                また、一般設定から「join実行チャンネルを読み上げる」機能をONにすると、<br/>\
                チャンネルが有効化されていなくてもjoinコマンドを入力したチャンネルを読み上げるようになります。<br/>\
                <br/>\
                【入退室アナウンスも読み上げない場合】<br/>\
                入退室アナウンス機能を有効化しているのにも関わらず読み上げない場合はVOISCORDの不具合の可能性があります。<br/>\
                その場合はサポートサーバーにてご報告をお願い致します。",
        open: false,
      },
      {
        title: "有料プランはありますか？",
        answer: "ありません。<br/>今後の予定としましては、予定が決まり次第、サポートサーバー内でアナウンス致します。",
        open: false,
      },
      {
        title: "配信はできますか？",
        answer:
          "どのような理由があろうと、VOISCORDが提供するデータの複製を固く禁じます。<br/>詳しくは利用規約をご覧下さい。",
        open: false,
      },
    ],
  },
  {
    kind: "使い方",
    questions: [
      {
        title: "キャラクターを変更したい",
        answer:
          "サーバー全体の標準キャラクターを変更する場合は`/config speaker`<br/>\
                ユーザー個別のキャラクターを変更する場合は`/speaker`で変更できます。",
        open: false,
      },
      {
        title: "自動入室しても読み上げない",
        answer:
          "自動入室で読み上げさせるためにはWEBダッシュボードから読み上げ設定を行う必要があります。<br/>\
                WEBダッシュボードのチャンネル設定画面から各VCの読み上げ設定を行って下さい。",
        open: false,
      },
      {
        title: "/config auto-joinコマンドが使えなくなりました。",
        answer: "/config auto-joinコマンドは廃止しました。<br/>今後はWEBダッシュボードからの設定をお願い致します。",
        open: false,
      },
      {
        title: "/vc系コマンドが使えなくなりました。",
        answer: "/vc系コマンドは廃止しました。<br/>今後はWEBダッシュボードからの設定をお願い致します。",
        open: false,
      },
    ],
  },
  {
    kind: "エラー",
    questions: [
      {
        title: "チャンネルが見つかりません",
        answer:
          "VOISCORDは`/join`コマンドを実行したユーザーが入っているボイスチャンネルを探して入室します。<br/>\
                そのためユーザーはコマンドを実行する前にボイスチャンネルに入室している必要があります。",
        open: false,
      },
      {
        title: "このコマンドを使うにはVOISCORDに以下の権限が必要です",
        answer:
          "入ろうとしているボイスチャンネルに適切な権限が与えられていません。<br/>\
                everyoneに「チャンネルを見る」「接続」「発言」の権限が付いていない場合によく見られるエラーです。<br/>\
                諸事情によりeveryoneに同権限が付けられない場合は、ボイスチャンネルの権限にVOISCORDロールを追加し、同権限を付与して下さい。",
        open: false,
      },
    ],
  },
]);

const toggleAnswer = (question: any) => {
  question.open = !question.open;
};
</script>

<style>
.faq-card {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style: none;
}

.faq-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.faq-card-header h3 {
  margin: 0;
  color: #333;
}

.faq-arrow-icon {
  font-size: 16px;
  transition: transform 0.3s;
  color: #333;
}

.faq-arrow-rotate {
  transform: rotate(180deg);
}

.faq-card-content {
  padding: 10px;
}
</style>
