<template>
  <div class="vcGuildVoiceChannels">
    <div class="vcGuildVoiceChannels_header">
      <v-text-field
        v-model="query"
        class="vcGuildVoiceChannels_search"
        prepend-inner-icon="mdi-magnify"
        persistent-placeholder
        variant="solo"
        hide-details
        :placeholder="placeholder"
      />
    </div>
    <div class="vcGuildVoiceChannels_list flex-grow-1">
      <v-list class="bg-transparent">
        <v-list-item :link="false" title="join実行チャンネルを読み上げる">
          <template #append>
            <v-switch
              v-model="includeCommandChannel"
              :class="{ 'text-inactive': !includeCommandChannel }"
              hide-details
              inset
              color="primary"
            />
          </template>
        </v-list-item>
        <v-list-item class="text-info" :link="false" title="接続VC内チャンネルを読み上げる">
          <template #append>
            <v-switch
              v-model="readVoiceChannelItself"
              :class="{ 'text-inactive': !readVoiceChannelItself }"
              hide-details
              inset
              color="primary"
            />
          </template>
        </v-list-item>
        <v-list-item class="text-warning" :link="false" title="スレッドも読み上げる">
          <template #append>
            <v-switch
              v-model="inheritReadabilityToThread"
              :class="{ 'text-inactive': !inheritReadabilityToThread }"
              hide-details
              inset
              color="primary"
            />
          </template>
        </v-list-item>
      </v-list>
      <v-divider class="my-2" />
      <VcGuildChannelItems v-if="!isGuildChangeLoading" :channels="voiceChannels" :link="true" height="64">
        <template #append="{ channel }">
          <!-- <VcGuildVoiceChannelAutoJoinSwitch
            v-if="channel.id !== guildChannel?.id && !isCategory(channel)"
            :channel="channel"
          />
          <span v-if="channel.id === guildChannel?.id" class="text-success"> 自動接続 </span> -->
          <v-chip
            v-if="channel.vChannel.auto_join"
            class="vcGuildVoiceChannels_autoJoinChip"
            prepend-icon="mdi-check"
            color="secondary"
            label
            >自動接続</v-chip
          >
        </template>
      </VcGuildChannelItems>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Channel, VChannel } from "@/types";
import { ref, computed, inject, watch, type Ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useAPI } from "@/composable/useAPI";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useChannelStore } from "@/stores/useChannelStore";
import { useVChannelStore } from "@/stores/useVChannelStore";
import { useAsyncUpdaterStore } from "@/stores/useAsyncUpdaterStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { isCategory, searchVoiceChannels } from "@/utilities/channel";

const guildChannel = inject<Ref<Channel>>("guildChannel");

const route = useRoute();
const { mobile } = useDisplay();

const vGuildStore = useVGuildStore();
const channelStore = useChannelStore();
const vChannelStore = useVChannelStore();
const loadingStore = useLoadingStore();
const { vGuild } = storeToRefs(vGuildStore);
const { channels } = storeToRefs(channelStore);
const { vChannels } = storeToRefs(vChannelStore);
const { isGuildChangeLoading } = storeToRefs(loadingStore);

const query = ref("");

const vChannelsMap = computed(() => {
  return vChannels.value.reduce(
    (map, vChannel) => {
      return map.set(vChannel.channel_id, vChannel);
    },
    new Map() as Map<string, VChannel | undefined>,
  );
});
const buildDefaultVChannel = (channel: Channel) => ({
  channel_id: channel.id,
  auto_join: false,
  channel_ids: [],
  ignore_channel_ids: [],
});
const injectVChannel = (channels: Channel[]): Channel[] => {
  return channels.map((channel) => {
    return {
      ...channel,
      vChannel: vChannelsMap.value.get(channel.id) || buildDefaultVChannel(channel),
      children: injectVChannel(channel.children),
    };
  });
};
const voiceChannels = computed(() => {
  if (!guildChannel) return [];

  return injectVChannel([guildChannel.value].concat(searchVoiceChannels(channels.value, query.value)));
});
const placeholder = computed(() => {
  return mobile.value ? "検索（名前またはチャンネルID）" : "VCを検索（名前またはチャンネルID）";
});

const propertyUpdater = useAsyncUpdaterStore().createPropertyUpdater(vGuild, (params, onAfterUpdate) => async () => {
  return useAPI()
    .patch(`/api/v_guilds/${vGuild.value.guild_id}`, params)
    .then((response) => {
      useSnackbarStore().addTopRight({ color: "success", text: "保存しました" });
      onAfterUpdate(() => vGuildStore.$patch({ vGuild: response.data }))();
    });
});
const inheritReadabilityToThread = propertyUpdater("inherit_readability_to_thread");
const includeCommandChannel = propertyUpdater("include_command_channel");
const readVoiceChannelItself = propertyUpdater("read_voice_channel_itself");

const initialize = () => {
  query.value = "";
};
watch(route, initialize);
initialize();
</script>

<style lang="scss" scoped>
@use "@/stylesheets/mixin.scss";

.vcGuildVoiceChannels_header {
  margin-top: 32px;
  min-height: 80px;
}

.vcGuildVoiceChannels_search {
  width: 100%;
  margin-bottom: 8px;
}

.vcGuildVoiceChannels_list {
  @include mixin.no-scrollbar;

  overflow-y: auto;
  position: relative;
}

.vcGuildVoiceChannels_list {
  :deep(.vcGuildChannelItem):not(.is-category) {
    border-color: rgba(var(--v-border-color)), var(--v-border-opacity);
    border-style: solid;
    border-width: 0;
    border-radius: 4px;
    background-color: rgba(#fff, 0.08);
    margin-bottom: 8px;

    &:hover {
      background-color: rgba(var(--v-theme-primary), 0.25);
    }

    &.v-list-item--active {
      background-color: rgba(var(--v-theme-primary), 0.4);
    }
  }
}

.vcGuildVoiceChannels_autoJoinChip {
  pointer-events: none;
}
</style>
