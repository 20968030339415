<template>
  <VcGuildInvite />
  <div v-if="vGuild && vGuild.id !== '0' && !isGuildChangeLoading" class="pa-2">
    <div v-if="managable" class="p-member__header">
      <v-autocomplete
        v-model="member"
        v-model:search="memberSearchQuery"
        :loading="memberSearchLoading"
        :items="searchedMembers"
        :hide-no-data="memberSearchLoading || !memberSearchQuery"
        :prepend-inner-icon="memberSearchLoading ? 'mdi-loading mdi-spin' : 'mdi-magnify'"
        class="p-member__search"
        item-title="name"
        placeholder="ユーザーを検索"
        persistent-placeholder
        no-data-text="一致するユーザーがいません"
        variant="solo"
        hide-details
        clearable
        no-filter
        return-object
      >
        <template #selection="{ item }">
          <v-list-item
            class="p-member__search-selection"
            :prepend-avatar="item.raw.image"
            :title="item.raw.nick || item.raw.name"
            :subtitle="item.raw.username"
          />
        </template>
        <template #item="{ props, item }">
          <v-list-item
            v-bind="props"
            :prepend-avatar="item.raw.image"
            :title="item.raw.nick || item.raw.name"
            :subtitle="item.raw.username"
          />
        </template>
      </v-autocomplete>
      <div>
        <v-checkbox
          v-model="searchUserId"
          class="p-member__search-target"
          label="ユーザーID（完全一致）"
          density="compact"
          color="blue-accent-2"
          hide-details
        />
        <v-checkbox
          v-model="searchName"
          class="p-member__search-target"
          label="ユーザー名・ニックネーム（前方一致）"
          density="compact"
          color="blue-accent-2"
          hide-details
        />
      </div>
    </div>

    <div v-if="!managable && member" class="p-member__header">
      <div class="mb-2">
        <v-alert
          v-if="vGuild.user_customization"
          type="info"
          text="サーバー管理権限の無いサーバーでは自分のメンバー設定のみ変更することができます。"
          variant="tonal"
        />
        <v-alert v-else type="warning" variant="tonal">
          <template #text>
            このサーバーではユーザー設定の変更が許可されていません。 変更したい場合は
            <b
              ><span class="pa-1 text-white">{{ guildStore.guild?.name }}</span></b
            >
            のサーバー管理者へお問合せください。
          </template>
        </v-alert>
      </div>

      <v-list>
        <v-list-item
          class="p-member__search-selection"
          :prepend-avatar="member.image"
          :title="member.nick || member.name"
          :subtitle="member.username"
        />
      </v-list>
    </div>

    <div v-if="member">
      <v-card class="vc-setting" flat>
        <v-card-text>
          <VcSettingAutoSaveChip />
          <VcSettingServerSync v-model="speaker" label="話者" :initial-value="'結月ゆかり'" :disabled="disabled" />
          <VcSettingServerSync v-model="volume" label="音量" :initial-value="100" :disabled="disabled" />
          <VcSettingServerSync
            v-model="speed"
            label="スピード"
            only-subscribed
            :initial-value="100"
            :disabled="disabled"
          />
          <VcSettingServerSync
            v-model="pitch"
            label="ピッチ"
            only-subscribed
            :initial-value="100"
            :disabled="disabled"
          />
          <VcSettingServerSync
            v-model="callByNickname"
            label="ニックネームを使用"
            :initial-value="true"
            :disabled="disabled"
          />
          <VcSettingServerSync
            v-model="messageFormat"
            label="メッセージフォーマット"
            :initial-value="'{{message}}'"
            :disabled="disabled"
          />
        </v-card-text>
      </v-card>

      <VcSettingSelect
        v-if="speaker !== null"
        v-model="speaker"
        label="話者"
        width="260"
        syncable
        :items="['結月ゆかり', '琴葉葵', '紲星あかり']"
        :disabled="disabled"
      />
      <VcSettingNumber
        v-if="volume !== null"
        v-model="volume"
        syncable
        percent
        :initial-value="100"
        :min="0"
        :max="200"
        label="音量"
        :disabled="disabled"
      />
      <VcSettingNumber
        v-if="speed !== null && vGuildStore.isSubscribed"
        v-model="speed"
        percent
        :initial-value="100"
        :min="80"
        :max="160"
        label="スピード"
        only-subscribed
        :disabled="disabled"
      />
      <VcSettingNumber
        v-if="pitch !== null && vGuildStore.isSubscribed"
        v-model="pitch"
        percent
        :initial-value="100"
        :min="80"
        :max="160"
        label="ピッチ"
        only-subscribed
        :disabled="disabled"
      />
      <VcSettingSwitch
        v-if="callByNickname !== null"
        v-model="callByNickname"
        label="ニックネームを使用"
        :disabled="disabled"
      />
      <VcSettingFormatBase
        v-if="messageFormat !== null"
        v-model="messageFormat"
        label="メッセージフォーマット"
        dynamic-flg-label="発言者の名前を読み上げる"
        :dynamic-format-on="'{{name}} {{message}}'"
        :dynamic-format-off="'{{message}}'"
        :dynamic-format-regexp="/^\{\{name\}\}.*\{\{message\}\}/"
        :basic-variables="['name', 'message']"
        :only-subscribed-variables="['channel', 'time']"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { User } from "@/types";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useAPI } from "@/composable/useAPI";
import { useDebounce } from "@/composable/useLodash";
import { useGuildStore } from "@/stores/useGuildStore";
import { useUserStore } from "@/stores/useUserStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useMemberStore } from "@/stores/useMemberStore";
import { useVMemberStore } from "@/stores/useVMemberStore";
import { useAsyncUpdaterStore } from "@/stores/useAsyncUpdaterStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const route = useRoute();

const guildStore = useGuildStore();
const userStore = useUserStore();
const vGuildStore = useVGuildStore();
const memberStore = useMemberStore();
const vMemberStore = useVMemberStore();
const loadingStore = useLoadingStore();

const { user } = storeToRefs(userStore);
const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);
const { vMember } = storeToRefs(vMemberStore);
const { isGuildChangeLoading } = storeToRefs(loadingStore);

const managable = computed(() => {
  return !guildStore.isGuildNotManaged || vGuildStore.vGuild.is_moderator;
});

const disabled = computed(() => {
  return !managable.value && !vGuild.value.user_customization;
});

const member = ref<User>();
const memberSearchQuery = ref("");
const memberSearchLoading = ref(false);
const searchedMembers = ref([] as User[]);
const searchUserId = ref(true);
const searchName = ref(true);

const propertyUpdater = useAsyncUpdaterStore().createPropertyUpdater(vMember, (params, onAfterUpdate) => async () => {
  const response = await useAPI().patch(
    `/api/v_guilds/${vGuild.value.guild_id}/v_members/${vMember.value?.user_id}`,
    params,
  );
  useSnackbarStore().addTopRight({ color: "success", text: "保存しました" });
  onAfterUpdate(() => vMemberStore.$patch({ vMember: response.data }));
});

const speaker = propertyUpdater("speaker");
const volume = propertyUpdater("volume");
const speed = propertyUpdater("speed");
const pitch = propertyUpdater("pitch");
const callByNickname = propertyUpdater("call_by_nickname");
const messageFormat = propertyUpdater("message_format");

const debouncedSearchMember = useDebounce(async (query: string) => {
  if (query && (searchUserId.value || searchName.value)) {
    searchedMembers.value = await memberStore.searchFromVGuild({
      query,
      search_user_id: searchUserId.value,
      search_name: searchName.value,
    });
  } else {
    searchedMembers.value = [];
  }
  memberSearchLoading.value = false;
}, 500);
const searchMember = () => {
  const query = memberSearchQuery.value.trim();
  memberSearchLoading.value = !!query;
  debouncedSearchMember(query);
};

watch(memberSearchQuery, searchMember);
watch(member, async () => {
  if (member.value) {
    await vMemberStore.fetchVMember(member.value.id);
  } else {
    vMemberStore.clear();
  }
});

const initialize = async () => {
  if (!user.value) throw "AssertionError";
  if (!route.path.match(/\/server\/\d+\/member/)) return;

  if (vGuild.value.guild_id !== "0") {
    loadingStore.setIsGuildChangeLoading(true);
    memberSearchQuery.value = "";
    searchedMembers.value = [];
    const members = await memberStore.searchFromVGuild({
      query: user.value.id,
      search_user_id: true,
      search_name: false,
    });
    if (members && members.length) {
      member.value = members[0];
    }
  }
  loadingStore.setIsGuildChangeLoading(false);
};
watch(vGuild, initialize);
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});
</script>

<style lang="scss">
.p-member__header {
  margin: 32px auto 60px;
  max-width: 800px;
}

.p-member__search {
  width: 100%;
  margin-bottom: 24px;
}

.p-member__search-selection {
  width: 240px;
  max-width: 240px;

  .is-mobile & {
    width: 200px;
    max-width: 200px;
  }
}

.p-member__search-target {
  width: 300px;
  max-width: 300px;
  margin: -16px 0;
}

.v-autocomplete .v-field .v-field__input > input {
  align-self: center;
}

.v-autocomplete .v-field--dirty .v-autocomplete__selection {
  margin: -12px 0;
}
</style>
