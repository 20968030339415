<template>
  <div class="l-dashboard">
    <v-app :class="{ 'is-mobile': mobile }">
      <v-app-bar elevation="3" :color="isNightly ? 'nightly' : undefined">
        <template #prepend>
          <v-app-bar-nav-icon v-if="!mobile" @click.stop="userStore.toggleDrawer()" />
        </template>
        <template v-if="mobile">
          <template v-if="guild">
            <router-link class="l-dashboard__title" to="/dashboard">
              <v-app-bar-title>
                <GuildIcon :guild="guild" :size="44" />
              </v-app-bar-title>
            </router-link>
            <v-menu v-if="menus.length">
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  class="l-dashboard__title-menu justify-start flex-grow-1 ma-3"
                  :prepend-icon="currentMenu?.icon"
                  append-icon="mdi-menu-down"
                  elevation="1"
                  :title="currentMenu?.title"
                />
              </template>
              <v-list>
                <v-list-item
                  v-for="menu in menus"
                  :key="menu.title"
                  :title="menu.title"
                  :to="menu.to"
                  :prepend-icon="menu.icon"
                />
              </v-list>
            </v-menu>
          </template>
          <template v-else>
            <v-btn v-if="showBackHome" variant="plain" prepend-icon="mdi-chevron-left" size="large" to="/dashboard">
              サーバー選択へ戻る
            </v-btn>
            <v-spacer />
          </template>
        </template>
        <template v-else>
          <v-app-bar-title class="ml-2">
            <router-link to="/">
              <template v-if="isNightly">
                VOISCORD <span class="text-nightly-secondary"><b>NIGHTLY</b></span>
              </template>
              <template v-else>VOISCORD</template>
            </router-link>
          </v-app-bar-title>
          <v-spacer />
          <v-btn
            v-for="headerNavigation in headerNavigations"
            :key="headerNavigation.name"
            class="mr-2 bg-grey-darken-3"
            :class="headerNavigation.class"
            :to="headerNavigation.to"
            :href="headerNavigation.href"
            :color="headerNavigation.color"
            :prepend-icon="headerNavigation.icon"
            :append-icon="headerNavigation.external ? 'mdi-open-in-new' : undefined"
            :target="headerNavigation.href && '_blank'"
            :rel="headerNavigation.href && 'noreferrer'"
            size="large"
            @click="headerNavigation.onClick"
          >
            {{ headerNavigation.name }}
          </v-btn>
        </template>
        <template #append>
          <v-menu>
            <template #activator="{ props }">
              <v-btn
                v-if="user"
                v-bind="props"
                class="l-dashboard__user-info pl-2 pr-1"
                elevation="3"
                block
                :style="{ backgroundColor: user.accent_color }"
              >
                <v-avatar color="primary" size="32">
                  <v-img :src="user.image" width="32" />
                </v-avatar>
                <div v-if="!mobile" class="l-dashboard__user-info--name">
                  {{ user.name }}
                </div>
                <v-icon icon="mdi-menu-down" />
              </v-btn>
              <v-skeleton-loader v-else type="button" height="48" max-height="48" width="188" max-width="188" />
            </template>
            <v-list>
              <v-list-item
                v-for="headerNavigation in headerNavigations"
                :key="headerNavigation.name"
                :title="headerNavigation.name"
                :prepend-icon="headerNavigation.external ? 'mdi-open-in-new' : headerNavigation.icon"
                :color="headerNavigation.color"
                :to="headerNavigation.to"
                :href="headerNavigation.href"
                :target="headerNavigation.href && '_blank'"
                :rel="headerNavigation.href && 'noreferrer'"
                @click="headerNavigation.onClick"
              />
              <v-list-item key="reload" :disabled="!reloadAvailableEnable" @click="clearCache">
                <v-list-item-title>{{ reloadText }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                key="logout"
                tag="button"
                type="submit"
                class="w-100 text-left"
                @click="logoutFormRef?.submit()"
              >
                <v-list-item-title class="text-danger">ログアウト</v-list-item-title>
              </v-list-item>
            </v-list>
            <form ref="logoutFormRef" class="d-none" method="post" action="/logout">
              <input type="hidden" name="_method" value="delete" />
            </form>
          </v-menu>
        </template>
      </v-app-bar>

      <DashboardLayoutDrawer />

      <v-main>
        <div class="h-100 w-100" style="position: relative">
          <div class="h-100 w-100">
            <v-container class="pa-0">
              <router-view v-slot="{ Component }">
                <component :is="Component" />
              </router-view>
            </v-container>
          </div>
          <v-overlay
            :model-value="!isSetupLoading && isGuildChangeLoading"
            contained
            absolute
            persistent
            content-class="w-100"
            scrim="#111"
          >
            <v-progress-linear color="discord-primary" indeterminate :height="6" />
          </v-overlay>
        </div>
      </v-main>
    </v-app>
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="snackbar.id"
      close-on-content-click
      :class="`l-dashboard__snackbar-${index + 1}`"
      :model-value="true"
      :location="snackbar.location"
      :location-strategy="snackbar.locationStrategy"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      @update:model-value="snackbarStore.remove(snackbar.id)"
    >
      {{ snackbar.text }}
    </v-snackbar>
    <v-dialog
      v-model="showHowTo"
      class="l-dashboard__how_to"
      :class="{ 'is-mobile': mobile }"
      :fullscreen="mobile"
      eager
      min-height="calc(100vh - 128px)"
    >
      <VcHowToPdfContainer v-model="showHowTo" />
    </v-dialog>
    <v-overlay :model-value="isSetupLoading" class="align-center justify-center" persistent>
      <v-progress-circular color="discord-primary" indeterminate size="128" />
    </v-overlay>
  </div>
</template>

<script setup lang="ts">
import type { VUser } from "@/types";
import { ref, computed, onMounted } from "vue";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { VSkeletonLoader } from "vuetify/labs/VSkeletonLoader";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useAPI } from "@/composable/useAPI";
import { useMenuStore } from "@/stores/useMenuStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useUserStore } from "@/stores/useUserStore";
import { useVUserStore } from "@/stores/useVUserStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

type HeaderNavigation = {
  name: string;
  icon?: string;
  class?: string;
  color?: string;
  to?: string;
  href?: string;
  external?: boolean;
  onClick?: () => void;
};

const { siteUrl } = useRuntimeConfig();

const { mobile } = useDisplay();
const route = useRoute();

const nowTime = ref(new Date().getTime());
const isNightly = ref(siteUrl.includes("nightly"));
const showHowTo = ref(false);

const userStore = useUserStore();
const vUserStore = useVUserStore();
const guildStore = useGuildStore();
const menuStore = useMenuStore();
const snackbarStore = useSnackbarStore();
const loadingStore = useLoadingStore();

const { user } = storeToRefs(userStore);
const { vUser } = storeToRefs(vUserStore);
const { guild } = storeToRefs(guildStore);
const { menus } = storeToRefs(menuStore);
const { snackbars } = storeToRefs(snackbarStore);
const { isSetupLoading, isGuildChangeLoading } = storeToRefs(loadingStore);

const currentMenu = computed(() => {
  return menus.value.find((menu) => route.path === menu.to);
});

const showBackHome = computed(() => {
  return route.path !== "/dashboard";
});

onMounted(() => {
  setInterval(() => {
    nowTime.value = new Date().getTime();
  }, 1000);
});

const headerNavigations = computed<HeaderNavigation[]>(() => {
  const toVoiscord: HeaderNavigation = {
    name: "VOISCORD",
    href: "https://voiscord.net/dashboard",
    class: "bg-discord-primary",
    external: true,
  };
  const toNightly: HeaderNavigation = {
    name: "NIGHTLY",
    href: "https://nightly.voiscord.net/dashboard",
    class: "bg-nightly",
    color: "nightly-secondary",
    external: true,
  };
  const onClick = () => {
    showHowTo.value = true;
  };

  return [
    isNightly.value ? toVoiscord : toNightly,
    ...(mobile.value ? [] : [{ name: "使い方", onClick, icon: "mdi-book-open-variant" }]),
    { name: "コマンド", to: "/commands", icon: "mdi-apple-keyboard-command" },
    { name: "FAQ", to: "/questions", icon: "mdi-help-circle" },
    {
      name: "お問い合わせ",
      href: "https://discord.gg/HTJ8NTvf2V",
      icon: "mdi-chat-processing",
    },
  ];
});

const logoutFormRef = ref<HTMLFormElement>();

const reloadAvailableSeconds = computed(() => {
  const reloadAvailableAt = vUser?.value?.reload_available_at;
  if (!reloadAvailableAt) return 60;

  const reloadAvailableAtTime = new Date(reloadAvailableAt).getTime();
  return nowTime.value > reloadAvailableAtTime ? 0 : Math.ceil((reloadAvailableAtTime - nowTime.value) / 1000);
});
const reloadAvailableEnable = computed(() => {
  return reloadAvailableSeconds.value === 0;
});
const reloadText = computed(() => {
  return reloadAvailableEnable.value
    ? "ユーザーキャッシュをクリア"
    : `あと${reloadAvailableSeconds.value}秒お待ちください...`;
});

const clearCache = async () => {
  const api = useAPI();
  const { data } = await api
    .post(`/api/v_users/${user?.value?.id}/clear_cache`)
    .catch(() => ({}) as { data: { reload_available_seconds?: number } });
  if (data.reload_available_seconds) {
    snackbarStore.addTopRight({
      color: "danger",
      text: `${data.reload_available_seconds}秒後に再度更新可能になります。`,
    });
  } else if (!data) {
    snackbarStore.addDefaultGetError();
  } else {
    vUserStore.setVUser(data as VUser);
    location.reload();
  }
};
</script>

<style lang="scss">
html {
  font-size: 14px;
  background-color: #37393e;
}

input[type="number"] {
  appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.l-dashboard__title {
  text-decoration: none;
  flex: 0 1 fit-content;
  height: 100%;
  display: flex;
  align-items: center;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: rgba(255, 255, 255, 0.87);
  }
}

.l-dashboard__title-menu {
  grid-template-columns: auto 1fr auto;
  height: 48px;
}

.l-dashboard__user-info {
  &.v-btn.v-btn--density-default {
    height: 48px;
  }
}

.l-dashboard__user-info--name {
  text-transform: none;
  min-width: 120px;
  text-align: left;
  margin-left: 8px;
}

.l-dashboard {
  position: relative;
}

.l-dashboard__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.vc-setting {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 90px;
  margin: 8px auto;
  max-width: 800px;

  .v-card-text {
    padding: calc(1rem + 11px) 1rem;
  }
}

.input--hide-spin input::-webkit-outer-spin-button,
.input--hide-spin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-snackbar {
  opacity: 0;
  transition: all 0.2s ease-in;

  @for $i from 1 through 5 {
    &.l-dashboard__snackbar-#{$i} {
      opacity: 1;
      transform: translateY(60px * $i);
    }
  }
}

.v-snackbar__wrapper {
  min-width: 0 !important;
}

.v-tooltip .v-overlay__content.vcGuildTextChannelReadState_tooltip {
  background-color: black;
  line-height: 32px;
  font-size: 14px;

  &::before {
    content: "";
    position: absolute;
    bottom: -7px;
    right: 58px;
    border: 4px solid transparent;
    border-top: 4px solid black;
  }
}

.v-skeleton-loader__button {
  height: 100%;
  width: 100%;
  margin: 0;
  max-width: none;
}

a,
a:visited {
  color: white;
  text-decoration: none;
}
</style>
