<template>
  <component :is="layout()" />
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import NoneLayout from "@/layouts/NoneLayout.vue";
import LpLayout from "@/layouts/LpLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";

const layouts = {
  NoneLayout,
  LpLayout,
  DashboardLayout,
};
type layoutKeys = keyof typeof layouts;

const layout = () => {
  const layoutKey = useRouter().currentRoute.value.meta.layout as layoutKeys | undefined;
  return (layoutKey && layouts[layoutKey]) || DashboardLayout;
};
</script>
