import type { VUser } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";

export const useVUserStore = defineStore("v_users", {
  state: () => {
    return {
      vUser: undefined as VUser | undefined,
    };
  },

  actions: {
    async fetchVUser(userId: string) {
      const api = useAPI();
      await api
        .get(`/api/v_users/${userId}`)
        .then((response) => (this.vUser = response.data))
        .catch(() => this.$reset());
    },
    setVUser(vUser: VUser) {
      this.vUser = vUser;
    },
  },
});
