<template>
  <div class="mt-4 pa-2">
    <div class="text-h5 text-center mb-4">サーバーを選択</div>
    <v-card v-for="(userGuilds, index) in guildsByStatus" :key="index" class="mb-4">
      <v-card-title>{{ guildGroupNames[index] }}</v-card-title>
      <div class="p-dashboard__guild-groups d-flex flex-wrap ma-3">
        <GuildIcon v-for="guild in userGuilds" :key="guild.id" :guild="guild" :size="80" @click="onClick(guild)" />
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import type { Guild } from "@/types";
import { storeToRefs } from "pinia";
import { computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useGuildStore } from "@/stores/useGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const router = useRouter();

const loadingStore = useLoadingStore();
const guildStore = useGuildStore();

const { guilds } = storeToRefs(guildStore);

const guildsByStatus = computed(() => {
  if (!guilds.value) {
    return [];
  }

  return [guildStore.managedJoinedGuilds, guildStore.notManagedJoinedGuilds, guildStore.managedNotJoinedGuilds];
});

const guildGroupNames = ["BOT導入済み（管理権限あり）", "BOT導入済み（管理権限なし）", "BOT未導入"];

const onClick = (guild: Guild) => {
  loadingStore.setIsGuildChangeLoading(true);
  nextTick(() => {
    router.push(`/server/${guild.id}/general`);
  });
};
</script>

<style lang="scss">
.p-dashboard__guild-groups {
  gap: 8px;
}
</style>
